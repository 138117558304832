/**
 * @module womeninbotany.infopage.module
 */
import womeninbotanyInfopageComponent from 'womeninbotany/infopage/component.js';

/**
 * @type {!angular.Module}
 */
const exports = angular.module('womeninbotanyInfopageModule', [
  womeninbotanyInfopageComponent.name,
]);


export default exports;
