/**
 * @module womeninbotany.timeline.timelineComponent
 */

import googAsserts from 'goog/asserts.js';
import womeninbotanyMiscScrolling from 'womeninbotany/misc/ScrollingService.js';
import * as olObj from 'ol/obj.js';
import womeninbotanyTimelineD3Timeline from 'womeninbotany/timeline/d3Timeline.js';

import {select} from 'd3-selection';
const d3 = {
  select,
};

/**
 * @type {!angular.Module}
 */
const exports = angular.module('womeninbotanyTimeline', [
  womeninbotanyMiscScrolling.module.name,
]);


/**
 * Provides a directive used to insert an histogramm
 * in the DOM.
 *
 * @htmlAttribute {?Object} womeninbotany-timeline The events per year data.
 * @htmlAttribute {Array<number>} womeninbotany-timeline-dates Min and max year.
 * @htmlAttribute {womeninbotanyx.timeline.TimelineOptions} womeninbotany-timeline-options The options.
 * @param {womeninbotany.Scrolling} womeninbotanyScrolling service
 * @return {angular.Directive} Directive Definition Object.
 * @ngInject
 * @ngdoc directive
 * @ngname womeninbotanyTimelineDirective
 */
exports.directive_ = function (womeninbotanyScrollingService) {
  return {
    restrict: 'A',
    /**
     * @param {angular.Scope} scope Scope.
     * @param {angular.JQLite} element Element.
     * @param {angular.Attributes} attrs Atttributes.
     */
    link(scope, element, attrs) {
      const timelineDates = attrs['womeninbotanyTimelineDates'];
      googAsserts.assert(timelineDates !== undefined);

      const timelineOptions = attrs['womeninbotanyTimelineOptions'];
      googAsserts.assert(timelineOptions !== undefined);

      const selection = d3.select(element[0]);
      let timeline, eventData;

      scope.$watchCollection(timelineOptions, (newVal) => {

        const options = /** @type {womeninbotanyx.timeline.TimelineOptions} */
          (olObj.assign({}, newVal));

        if (options !== undefined) {
          // proxy the setDatesCallback and maxExtentCallback in order to be
          // able to call $applyAsync
          // We're using $applyAsync here because the callback may be
          // called inside the Angular context.
          //
          // For that reason we use $applyAsync instead of $apply here.
          if (options.setDatesCallback !== undefined) {
            const origSetDatesCallback = options.setDatesCallback;
            options.setDatesCallback   = function (...args) {
              origSetDatesCallback(...args);
              scope.$applyAsync();
            };
          }

          if (options.maxExtentCallback !== undefined) {
            const origMaxExtentCallback = options.maxExtentCallback;
            options.maxExtentCallback   = function (...args) {
              origMaxExtentCallback(...args);
              scope.$applyAsync();
            };
          }

          timeline = womeninbotanyTimelineD3Timeline(options);
          refreshData();
        }
      });

      scope.$watch(
        attrs['womeninbotanyTimeline'],
        (newVal, oldVal) => {
          eventData = newVal;
          refreshData();
        }
      );

      scope.$watchCollection(
        attrs['womeninbotanyTimelineDates'],
        (newValue, oldValue) => {
          if (newValue !== null
              // empty year extent clears brush
              && (newValue.length == 0 || newValue.length == 2)) {
            timeline.moveBrushSelection(newValue);
            // womeninbotanyScrollingService.scrollToList();
          }
        }
      );

      function refreshData() {
        if (timeline !== undefined) {
          selection.datum(eventData).call(timeline);
        }
      }
    }
  };
};

exports.directive('womeninbotanyTimeline', exports.directive_);


export default exports;
