/**
 * @module womeninbotany.misc.ScrollingService
 */

/**
 * @constructor
 * @ngInject
 * @ngdoc service
 * @ngname womeninbotanyScrolling
 */
const exports = function() {

};

/**
 * @export
 */
exports.prototype.scrollToTop = (topValue = 0) => {
  $('html, body').animate({
    scrollTop: topValue
  }, 1000);
};

/**
 * @export
 */
exports.prototype.scrollToList = () => {
	if ( $('.botanist-list').offset() == undefined ) return;

  const offsetTopList   = $('.botanist-list').offset().top;
  const windowTopOffset = $(window).scrollTop();

  if ((windowTopOffset < offsetTopList / 2) || (windowTopOffset > offsetTopList + $(window).height())) {
	  $('html, body').animate({
      scrollTop: offsetTopList - $('.timelineContainer').height()
    }, 1000);
  }
};

/**
 * @export
 */
exports.prototype.toTopClicked = function() {
  const windowTopOffset = $(window).scrollTop();
  const anchorOffset    = $("#anchor").offset().top;
  if (windowTopOffset <= 5 || windowTopOffset > anchorOffset) {
    this.scrollToTop(anchorOffset - 6);
  } else {
    this.scrollToTop();
  }
};


/**
 * @type {!angular.Module}
 */
exports.module = angular.module('womeninbotanyScrollingService', []);

exports.module.service('womeninbotanyScrollingService', exports);

export default exports;
