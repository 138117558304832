/**
 * The Email service uses the
 * womeninbotany backend to send an email with edited or new entry data
 * @constructor
 * @param {angular.$http} $http Angular http service.
 * @ngInject
 * @ngdoc service
 * @ngname womeninbotanyEmail
 */
const exports = function($http, womeninbotanyServerURL) {

  /**
   * @type {angular.$http}
   * @private
   */
  this.http_ = $http;

  /**
   * @type {string}
   * @private
   */
  this.baseURL_ = womeninbotanyServerURL.replace(/\/$/, '');
};

/**
 * @export
 */
exports.prototype.sendEmail = function(entry) {
  const data = entry
  const url = `${this.baseURL_}/email`;

  return this.http_.post(url, data).then(
    (response) => {
      return response.data;
    },
    (message) => {
      console.log(message);
    }
  );
};

/**
 * @type {!angular.Module}
 */
exports.module = angular.module('womeninbotanyEmailService', []);

exports.module.service('womeninbotanyEmailService', exports);

export default exports;
