/**
 * @module womeninbotany.list.sortComponent
 */

/**
 * @type {!angular.Module}
 */
const exports = angular.module('womeninbotanySortBy', [
]);


exports.run(/* @ngInject */ ($templateCache) => {
  $templateCache.put('womeninbotany/list/sortComponent', require('./sort.html'));
});



exports.component_ = {
  controller: 'womeninbotanySortController',
  bindings: {
    reorder  : '&',
    resort   : '&',
    sortModes: '='
  },
  templateUrl: 'womeninbotany/list/sortComponent'
};

exports.component('womeninbotanySortBy', exports.component_);

/**
 * @constructor
 * @export
 * @ngInject
 * @ngdoc Controller
 * @ngname WomeninbotanySortByController
 */
exports.Controller_ = function ($scope) {
	/**
	 * @export
	 */
	this.orderModes = {
		asc : `asc`,
		desc: `desc`
	};

	/**
	 * @export
	 */
	this.sortMode = `Family Name`;

	/**
	 * @export
	 */
	this.orderMode = `asc`;

	/**
	 * @export
	 */
	this.sortModes;

	/**
	 * @export
	 */
	this.resort;

	/**
	 * @export
	 */
	this.reorder;
};

/**
 * @export
 */
exports.Controller_.prototype.selectSortMode = function (mode) {
	this.sortMode = this.sortModes[mode];
};

/**
 * @export
 */
exports.Controller_.prototype.changeOrderMode = function () {
	this.orderMode = (this.orderMode === this.orderModes.asc) ? this.orderModes.desc : this.orderModes.asc;
};


exports.controller('womeninbotanySortController', exports.Controller_);

export default exports;
