/**
 * @module womeninbotany.utility.scrollOnClickComponent
 */
import womeninbotanyMiscScrolling from 'womeninbotany/misc/ScrollingService.js';

/**
 * @type {!angular.Module}
 */
const exports = angular.module('womeninbotanyScrollOnClick', [
  womeninbotanyMiscScrolling.module.name,
]);


exports.run(/* @ngInject */ ($templateCache) => {
  $templateCache.put('womeninbotany/utility/scrollOnClickComponent', require('./scrollonclick.html'));
});


/**
 * Provides a directive for scrolling the window after clicking a button
 *
 * @param {angular.$window} $window Angular $window service.
 * @return {angular.Directive} Directive Definition Object.
 * @ngInject
 * @ngdoc directive
 * @ngname womeninbotanyScrollOnClickDirective
 *
 */
exports.directive_ = function ($window) {
	return {
		controller: 'womeninbotanyScrollOnClickController',
    controllerAs: '$ctrl',
    templateUrl: 'womeninbotany/utility/scrollOnClickComponent',
		scope: {},
		restrict: 'A',
		/**
		 * @param {angular.Scope} scope Scope.
		 * @param {angular.JQLite} element Element.
		 * @param {angular.Attributes} attrs Atttributes.
		 */
		link(scope, element, attrs) {
			const anchor           = $('#anchor');
			const stickyDiv        = $('#sticky-div');
			const navButton        = $('#nav');
			const navButtonText    = $('#nav-button-text');
			let stickAnimationDone = false;
			let stickPadding       = -105;

			angular.element($window).bind('scroll', function (e) {
				const windowTopOffset = $(window).scrollTop();
				const anchorTopOffset = anchor.offset().top;
				if (windowTopOffset + stickPadding >= anchorTopOffset) {
					if (stickAnimationDone === false) {
						stickyDiv.hide();
						stickyDiv.slideDown("medium", function () {
							// Animation complete.
							stickAnimationDone = true;
							// stickPadding = 5;  // why? unclear
							stickyDiv.addClass('stick');
							anchor.height(/** @type {number} */ (stickyDiv.height()));  //why? yet unclear
						});
					}
				} else {
					// Unstick the div.
					stickAnimationDone = false;
					// stickPadding = -105;   // seems unnecessary
					stickyDiv.removeClass('stick');
					anchor.height(0);
				}

				if (windowTopOffset <= 5) {
					navButton.removeClass("fa-rotate-180");
					navButtonText.text("To List");
				} else {
					navButton.addClass("fa-rotate-180");
					navButtonText.text("To Top");
				}

			});

			// animate list navigation
			 const navigation = angular.element(document.querySelector('.navigation'));
			 navigation.addClass('animated fadeInLeft');
		}
	};
};

exports.directive('womeninbotanyScrollOnClick', exports.directive_);

/**
 * @constructor
 * @export
 * @ngInject
 * @ngdoc Controller
 * @ngname WomeninbotanyScrollOnClickController
 */
exports.Controller_ = function ($scope, $rootScope, womeninbotanyScrollingService) {
	/**
	 * @type {boolean}
	 * @export
	 */
	this.infoPageHidden = true;

	this.womeninbotanyScrolling_ = womeninbotanyScrollingService;
};

/**
 *
 * @export
 */
exports.Controller_.prototype.toTopClicked = function () {
	this.womeninbotanyScrolling_.toTopClicked();
};


exports.controller('womeninbotanyScrollOnClickController', exports.Controller_);

export default exports;
