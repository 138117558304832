/**
 * @module womeninbotany.list.entryFieldComponent
 */

/**
 * @type {!angular.Module}
 */
const exports = angular.module('womeninbotanyEntryField', [
]);


exports.run(/* @ngInject */ ($templateCache) => {
  $templateCache.put('womeninbotany/list/entryFieldComponent', require('./entryfield.html'));
});

exports.component_ = {
	controller: 'womeninbotanyEntryFieldController',
	controllerAs: '$ctrlEntry',
	bindings: {
		id: '@',
		options: '=',
		deleteField: '&',
		addedFields: '='
	},
	templateUrl: 'womeninbotany/list/entryFieldComponent'
};

exports.component('womeninbotanyEntryField', exports.component_);


/**
 * @param {angular.Scope} $scope Angular scope.
 * @constructor
 * @export
 * @ngInject
 * @ngdoc Controller
 * @ngname WomeninbotanyEntryFieldController
 */
exports.Controller_ = function($scope) {
	/**
	 * @private
	 */
	this.scope = $scope;

	/**
	 * @export
	 */
	this.addedFields;

	/**
	 * @export
	 */
	this.options;

	/**
	 * @export
	 */
	this.id;

	/**
	 * @export
	 */
	this.fieldContent;

	/**
	 * @export
	 */
	this.fieldOption;

	$scope.$watch(function () {
    return this.fieldContent;
  }.bind(this),
  function (newValue, oldValue) {
	  this.addedFields.map(addedField => {
	  	if(addedField.id == this.id) {
			  addedField.content = newValue
		  }
		  return addedField
	  })
  }.bind(this), true);

	$scope.$watch(function () {
    return this.fieldOption;
  }.bind(this),
  function (newValue, oldValue) {
    this.addedFields.map(addedField => {
      if(addedField.id == this.id) {
        addedField.fieldOption = newValue
      }
      return addedField
    })
  }.bind(this), true);
};

/**
 *
 * @export
 */
exports.Controller_.prototype.delete = function() {
	this.deleteField({id: this.id});
};

exports.controller('womeninbotanyEntryFieldController', exports.Controller_);

export default exports;
