/**
 * @module unterwegs.map.PlaceService
 */

import olFormatGeoJSON from 'ol/format/GeoJSON.js';
import * as olProj from 'ol/proj.js';


/**
 * The Place service uses the
 * womeninbotany backend to get places
 * @constructor
 * @param {angular.$http} $http Angular http service.
 * @ngInject
 * @ngdoc service
 * @ngname womeninbotanyPlace
 */
const exports = function($http, womeninbotanyServerURL) {

  /**
  * @type {angular.$http}
  * @private
  */
  this.http_ = $http;

  /**
  * @type {string}
womeninbotanyServerURL  * @private
  */
  this.baseURL_ = womeninbotanyServerURL.replace(/\/$/, '');
};

/**
 * @param {womeninbotanyx.FilterRules} filterRules filterRules
 * @param {ol.Extent} extent Extent
 * @return {angular.$q.Promise} Promise.
 * @export
*/
exports.prototype.getPlaces = function(filterRules, extent) {
  extent = olProj.transformExtent(extent, 'EPSG:3857', 'EPSG:4326');

  const url = `${this.baseURL_}/places`;

  var data = {
    'bbox': extent.join(',')
  };

  if (filterRules !== null) {
    data['filter'] = {
    	'search': filterRules.search,
    	'categories': filterRules.categories,
	    'events': filterRules.events,
	    'place': filterRules.place,
	    'timelineDates': filterRules.timelineDates,
	    'plant': filterRules.plant
    };
  }

  return this.http_.post(url, data).then(
      this.handleGetData_.bind(this),
      (message) => {
          console.log(message);
      }
  );
};


/**
 * @param {number} place_id Place ID
 * @param {womeninbotanyx.FilterRules} filterRules filterRules
 * @return {angular.$q.Promise} Promise.
 * @export
*/
exports.prototype.getPairOfPlaces = function(place_id, filterRules) {

  const url = `${this.baseURL_}/place/${place_id}/pairs`;

  let data = {};
  if (filterRules !== null) {
    data['filter'] = filterRules;
  }

  return this.http_.post(url, data).then(
      this.handleGetData_.bind(this),
      (message) => {
          console.log(message);
      }
  );
};


/**
 * @param {string} search
 * @return {angular.$q.Promise} Promise.
 * @export
*/
exports.prototype.searchNames = function(search) {

  const url = `${this.baseURL_}/place/name?search=${search}&rows=7`;

  return this.http_.get(url).then(
    (response) => {
      return response.data;
    },
    (message) => {
      console.log(message);
    }
  );
};


/**
 * @param {angular.$http.Response} resp Ajax response.
 * @return {Array<ol.Feature>|undefined} array places features
 * @private
 */
exports.prototype.handleGetData_ = function(resp) {
  var geoJSON = resp.data;
  if (geoJSON == null || geoJSON.features == null) return;
  var geojsonFormat = new olFormatGeoJSON();
  var features = geojsonFormat.readFeatures(geoJSON);
  return features;
};


/**
 * @type {!angular.Module}
 */
exports.module = angular.module('womeninbotanyPlaceService', []);

exports.module.service('womeninbotanyPlaceService', exports);

export default exports;
