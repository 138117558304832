/**
 * @module womeninbotany.list.component
 */

import womeninbotanyMiscScrolling from 'womeninbotany/misc/ScrollingService.js';
import womeninbotanyBotanistService from 'womeninbotany/list/BotanistService.js';
import womeninbotanyListWhenScrolledComponent from 'womeninbotany/list/whenScrolledComponent.js';
import womeninbotanyStatisticsComponent from 'womeninbotany/statistics/component.js';

/**
 * @type {!angular.Module}
 */
const exports = angular.module('womeninbotanyList', [
  womeninbotanyBotanistService.module.name,
  womeninbotanyListWhenScrolledComponent.name,
  womeninbotanyMiscScrolling.module.name,
  womeninbotanyStatisticsComponent.name,
]);


exports.run(/* @ngInject */ ($templateCache) => {
  $templateCache.put('womeninbotany/list', require('./component.html'));
});



exports.component_ = {
  controller: 'womeninbotanyListController',
  bindings: {
    'filter': '=womeninbotanyListFilter',
	  'botanistsTotal': '=womeninbotanyBotanistsTotal',
	  'botanistsOnMap': '=womeninbotanyBotanistsOnMap',
	  'showOnMap': '&womeninbotanyShowOnMap',
  },
  templateUrl: 'womeninbotany/list'
};

exports.component('womeninbotanyList', exports.component_);

/**
 * @param {angular.Scope} $scope Angular scope.
 * @constructor
 * @ngInject
 * @ngdoc controller
 * @ngname WomenInBotanyFilterController
 */
exports.Controller_ = function(
  $scope, womeninbotanyBotanistService, womeninbotanyScrollingService) {

	/**
	 * @export
	 */
	this.filter;  // export for passing to statistics

	/**
	 * @private
	 */
	this.womeninbotanyBotanist_ = womeninbotanyBotanistService;

	/**
	 * @export
	 */
	this.alphabet = ['all', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

	/**
	 * @export
	 */
	this.selectedLetter = 'all';

	/**
	 * @export
	 */
	this.orderBy = 'asc';

	/**
	 * @export
	 */
	this.sortModes = {
		view: {
			familyName: `Family Name`,
			firstName : `First Name`,
			birthDate : `Date of Birth`,
			deathDate : `Date of Death`
		},
		sidx: {
			familyName: `familyname`,
			firstName : `firstname`,
			birthDate : `birthdate`,
			deathDate : `deathdate`
		}
	};

	/**
	 * @export
	 */
	this.sortBy = this.sortModes.sidx.familyName;

	this.rows   = 3;
	this.offset = 10;
	this.page   = 1;

	/**
	 * @export
	 */
	this.botanistsTotal;

	/**
	 * @export
	 */
	this.botanistsOnMap;

	/**
	 * @export
	 */
	this.botanists;

	/**
	 * @private
	 */
	this.womeninbotanyScrolling_ = womeninbotanyScrollingService;

	/**
	 * @private
	 */
	this.hasDataBeenFiltered = false;

	$scope.$watch(
		function () {
			return this.filter;
		}.bind(this),
		function (newValue, oldValue) {
			this.updateList_(newValue, oldValue);
		}.bind(this), true);
};

/**
 *
 * @export
 */
// Helper function as passing and calling functions to/ from grandchildren throws error
exports.Controller_.prototype.showOnMapFunction = function (longitude, latitude) {
	this.showOnMap({longitude: longitude, latitude: latitude});
};

/**
 * @export
 */
exports.Controller_.prototype.loadMore = function () {
	const numberOfRows = 10;
	const offset       = 10;
	this.womeninbotanyBotanist_.getList(this.filter, this.page, numberOfRows, offset, this.orderBy, this.sortBy).then(function (data) {
		this.page++;
		data.records.forEach(function (botanist) {
			this.botanists.push(botanist);
		}, this);
	}.bind(this));
};

/**
 * @export
 */
exports.Controller_.prototype.changeOrder = function (order) {
	this.orderBy = order;
	this.updateList_();
};

/**
 * @export
 */
exports.Controller_.prototype.changeSort = function (sortBy) {
	switch (sortBy) {
		case this.sortModes.view.familyName:
			this.sortBy = this.sortModes.sidx.familyName;
			break;
		case this.sortModes.view.firstName:
			this.sortBy = this.sortModes.sidx.firstName;
			break;
		case this.sortModes.view.birthDate:
			this.sortBy = this.sortModes.sidx.birthDate;
			break;
		case this.sortModes.view.deathDate:
			this.sortBy = this.sortModes.sidx.deathDate;
			break;
	}
	this.updateList_();
};

/**
 * @export
 */
exports.Controller_.prototype.selectLetter = function (letter) {
	if (letter == 'all') {
		delete this.filter['letter']
	} else {
		this.filter['letter'] = letter;
	}

	this.selectedLetter   = letter;
	this.updateList_();
};

/**
 * @private
 */
exports.Controller_.prototype.updateList_ = function (newValue, oldValue) {
	this.page          = 1;
	const numberOfRows = 20;
	const offset       = 0;
	this.womeninbotanyBotanist_.getList(this.filter, this.page, numberOfRows, offset, this.orderBy, this.sortBy).then(function (data) {
		this.page++;
		this.botanistsTotal = data['total'];
		this.botanistsOnMap = data['botanistsOnMap'];
		this.botanists      = [];
		data['records'].forEach(function (botanist) {
			this.botanists.push(botanist);
		}, this);

		if (this.hasDataBeenFiltered) {
			let timelineDatesChanged = false;

			if (oldValue && newValue) {
				if (oldValue.timelineDates.length != newValue.timelineDates.length) {
					timelineDatesChanged = true;
				} else {
					switch (newValue.timelineDates.length) {
						case 0:
							break;
						default:
							timelineDatesChanged = newValue.timelineDates[0] != oldValue.timelineDates[0];
							timelineDatesChanged = newValue.timelineDates[1] != oldValue.timelineDates[1];
					}
				}
			}
			if (newValue && oldValue) {
				if (newValue.search != oldValue.search || newValue.plant != oldValue.plant || newValue.place != oldValue.place) {
					this.womeninbotanyScrolling_.scrollToList();
				} else if (timelineDatesChanged) {
					// TODO: scroll to map when on bottom of the page
				}
			}
		} else {
			this.hasDataBeenFiltered = true;
		}
	}.bind(this));
};

exports.controller('womeninbotanyListController', exports.Controller_);

export default exports;
