/**
 * @module womeninbotany.map.spreadComponent
 */

import googAsserts from 'goog/asserts.js';
import * as olEvents from 'ol/events.js';
import olSourceVector from 'ol/source/Vector.js';
import olLayerVector from 'ol/layer/Vector.js';
import olStyleStyle from 'ol/style/Style.js';
import olStyleFill from 'ol/style/Fill.js';
import olGeomPoint from 'ol/geom/Point.js';
import olFeature from 'ol/Feature.js';
import olMap from 'ol/Map.js';
import olGeomCircle from 'ol/geom/Circle.js';


/**
 * @type {!angular.Module}
 */
const exports = angular.module('womeninbotanySpread', [
]);

exports.component_ = {
  controller: 'womeninbotanyMapSpreadController',
  bindings: {
    'getMapFn'    : '&womeninbotanySpreadMap',
    'placeStyleFn': '&womeninbotanySpreadPlaceStyle'
  }
};

exports.component('womeninbotanySpread', exports.component_);

/**
 * @constructor
 * @export
 * @ngInject
 * @ngdoc Controller
 * @ngname WomeninbotanySpreadController
 */
exports.Controller_ = function () {
  /**
   * @type {ol.Map}
   * @private
   */
  this.map_;

  /**
   * @type {ol.source.Vector}
   * @private
   */
  this.spreadedPointsSource_ = new olSourceVector({
    features: [],
    wrapX : false
  });

  /**
   * @type {ol.source.Vector}
   * @private
   */
  this.circleOfSpreadSource_ = new olSourceVector({
    features: []
  });

  /**
   * @type {ol.layer.Vector}
   * @private
   */
  this.spreadedPointsLayer_ = new olLayerVector({
    name          : 'spreaded-points',
    source          : this.spreadedPointsSource_,
    updateWhileAnimating  : true,
    updateWhileInteracting: true
  });

  /**
   * @type {ol.layer.Vector}
   * @private
   */
  this.circleOfSpreadLayer_ = new olLayerVector({
    name  : 'circle-of-spread',
    source: this.circleOfSpreadSource_,
    style : new olStyleStyle({
      fill: new olStyleFill({
        color: "rgba(255, 255, 255, 0.8)"
      })
    })
  });

  /**
   * @type {number}
   * @private
   */
  this.radiusOfPoint;

  /**
   * @type {number}
   * @private
   */
  this.dist2radius = 1; // ratio of distance between the points
  // to radius of the points

  /**
   * @type {boolean}
   * @private
   */
  this.spreaded = false;

  // wait until constructor has done its initialization
  this.$onInit = function () {
    this.map_ = this['getMapFn']();
    googAsserts.assertInstanceof(this.map_, olMap);

    let placeStyleFn = this['placeStyleFn']();

    // get radius out of placeStyleFn
    this.radiusOfPoint = placeStyleFn(
      new olFeature({
        geometry: new olGeomPoint([0, 0])
      }),
      this.map_.getView().getResolution()
    )[0].getImage().getRadius();

    this.spreadedPointsLayer_.setStyle(placeStyleFn);

    this.pointerMoveKey = olEvents.listen(
      this.map_, 'pointermove', this.handlePointerMove_, this
    );
    this.map_.addLayer(this.circleOfSpreadLayer_);
    this.map_.addLayer(this.spreadedPointsLayer_);
  };
};

exports.Controller_.prototype.handlePointerMove_ = function (evt) {

  if (!this.spreaded) {
  let features = evt.map.getFeaturesAtPixel( evt.pixel, {
      layerFilter: function (layer) {
        const name = layer.get('name');
        // return name != 'spreaded-points' && name != 'circle-of-spread';
        return name === 'places';
      }
    });

    if (features && features.length > 1) {
      let featuresSpreaded = [];
      features.forEach(function (feature) {
        let featureSpreaded = feature.clone();
        featureSpreaded.set('spreaded', true);
        featureSpreaded.set('familyname', feature.get('familyname'));
        featureSpreaded.set('firstname', feature.get('firstname'));
        featureSpreaded.set('place_id', feature.get('place_id'));
        featuresSpreaded.push(featureSpreaded);
      });

      let radiusOfCircle = this.getSpreadRadius_(featuresSpreaded.length)
        + 2 * this.radiusOfPoint * this.map_.getView().getResolution();
      let circle       = new olGeomCircle(
        featuresSpreaded[0].getGeometry().getCoordinates(),
        radiusOfCircle
      );

      this.circleOfSpreadSource_.addFeature(
        new olFeature({
          geometry: circle,
          placename: features[0].get('placename')
        })
      );
      this.spreadedPointsSource_.addFeatures(featuresSpreaded);
      let i = 0;
      featuresSpreaded.forEach(function (featureSpreaded) {
        const geom     = featureSpreaded.getGeometry();
        const coords_new = this.getPoint_(
          geom.getCoordinates(), i, featuresSpreaded.length
        );
        geom.setCoordinates(coords_new);
        i++;
      }.bind(this));
      this.spreaded = true;
    }
  }
  else {
    let found = evt.map.forEachFeatureAtPixel(evt.pixel, function () {
      return true;
    }, {
      layerFilter: function (layer) {
        return layer.get('name') === 'circle-of-spread';
      }
    });
    if (!found) {
      this.spreadedPointsSource_.clear();
      this.circleOfSpreadSource_.clear();
      this.spreaded = false;
    }
  }
};

exports.Controller_.prototype.getSpreadRadius_ = function (n) {
  const rP  = this.radiusOfPoint;
  const drP = this.dist2radius;
  const res = this.map_.getView().getResolution();

  return 1 / ( 2 * Math.PI ) * n * rP * ( 2 + drP) * res;
}

exports.Controller_.prototype.getPoint_ = function (coordinates, i, n) {

  let radius = this.getSpreadRadius_(n);

  let phi = 2 * Math.PI * i / n;

  let deltaX = radius * Math.sin(phi);
  let deltaY = radius * Math.cos(phi);

  let x = coordinates[0] + deltaX;
  let y = coordinates[1] + deltaY;
  return [x, y];
}


exports.controller('womeninbotanyMapSpreadController', exports.Controller_);

export default exports;
