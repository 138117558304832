/**
 * @module womeninbotany.reportpage.module
 */
import womeninbotanyReportpageComponent from 'womeninbotany/reportpage/component.js';

import './report-page.less';

/**
 * @type {!angular.Module}
 */
const exports = angular.module('womeninbotanyReportpageComponent', [
  womeninbotanyReportpageComponent.name,
]);


export default exports;
