/**
 * @module unterwegs.utility.DebounceService
 */

/**
 * @constructor
 * @ngInject
 * @ngdoc service
 * @ngname womeninbotanyUtility
 */
const exports  = function () {

};

exports.prototype.debounce = (func, wait, immediate) => {
	var timeout;
	return function () {
		var context = this, args = arguments;
		var later   = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};


/**
 * @type {!angular.Module}
 */
exports.module = angular.module('womeninbotanyDebounceService', []);

exports.module.service('womeninbotanyDebounceService', exports);

export default exports;
