/**
 * @module unterwegs.sidebar.GeonameService
 */

/**
 * The Geoname service uses the
 * womeninbotany backend to get geonames
 * @constructor
 * @param {angular.$http} $http Angular http service.
 * @ngInject
 * @ngdoc service
 * @ngname womeninbotanyGeoname
 */
const exports = function($http, womeninbotanyServerURL) {

  /**
  * @type {angular.$http}
  * @private
  */
  this.http_ = $http;

  /**
  * @type {string}
  * @private
  */
  this.baseURL_ = womeninbotanyServerURL.replace(/\/$/, '');
};

/**
 * @param {string} search
 * @return {angular.$q.Promise} Promise.
 * @export
*/
exports.prototype.searchNames = function(search) {
  const url = `${this.baseURL_}/geonames/list?name=${search}`;
  return this.http_.get(url).then(
    (response) => {
      return response.data;
    },
    (message) => {
      console.log(message);
    }
  );
};


/**
 * @type {!angular.Module}
 */
exports.module = angular.module('womeninbotanyGeonameService', []);

exports.module.service('womeninbotanyGeonameService', exports);

export default exports;
