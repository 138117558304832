/**
 * @module womeninbotany.list.module
 */
import womeninbotanyTimelineComponent from 'womeninbotany/timeline/timelineComponent.js';

import './timeline.less';

/**
 * @type {!angular.Module}
 */
const exports = angular.module('womeninbotanyTimelineModule', [
  womeninbotanyTimelineComponent.name,
]);


export default exports;
