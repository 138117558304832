/**
 * @module womeninbotany.statistics.d3Piechart
 */


import {mouse, select} from 'd3-selection';
import {scaleOrdinal} from 'd3-scale';
import {arc, pie} from 'd3-shape';

const d3 = {
  arc,
  mouse,
  pie,
  scaleOrdinal,
  select
}

/**
 * Provides a D3js component to be used to draw a piechart chart
 *
 * @constructor
 * @return {Object} D3js component.
 * @export
 */
const exports = function (options) {

	var color = d3.scaleOrdinal()
		.domain(['B', 'A', 'F', 'H', 'T', 'P', null])
		.range(['#588C7E', '#F2E394', '#F2AE72', '#D96459', '#8C4646', '#736091', '#f8d662']);

	var pie = d3.pie()
		.sort(null)
		.value(function (d) {
			return d.count;
		});

	return function (element) {
		element.selectAll('*').remove();
		const data = element.datum();

		if (data === undefined) {
			return;
		}

		const width  = 800, height = width;
		const radius = Math.min(width, height) / 2;

		var path = d3.arc()
			.outerRadius(radius - 80)
			.innerRadius(160); // donut chart

		const svg = element.append('svg')
			.attr("viewBox", "0 0 800 800");
		const g   = svg.append("g").attr("transform", "translate(" + width / 2 + "," + (height / 2 + 40) + ")");

		var arc = g.selectAll(".arc")
			.data(pie(data))
			.enter().append("g")
			.attr("class", "arc");

		const labelPos = d3.arc().innerRadius(radius - 44).outerRadius(radius - 44);

		arc.append("path")
			.attr("d", path)
			.attr("fill", function (d) {
				return color(d.data['id']);
			})
			.on("mouseover", function (d, i) {
				const coordinates = d3.mouse(d3.select(".pie-chart").node());
				const offset      = [20, 20];
				if (!d.data.hasOwnProperty('category')) {
					d.data.category = '';
				}
				d3.select(".pie-chart-tooltip")
					.style("left", (coordinates[0] + offset[0]) + "px")
					.style("top", (coordinates[1] + offset[1]) + "px")
					.style("opacity", 1);
				d3.select(".pie-chart-tooltip")
					.select(".pie-chart-tooltip-category")
					.text(d.data.category);
				d3.select(".pie-chart-tooltip")
					.select(".pie-chart-tooltip-results")
					.text(d.data.count);
			})
			.on("mouseout", function (d) {
				d3.select(".pie-chart-tooltip")
					.style("opacity", 0);
			});

		const arcSvg = arc.append("svg")
			.attr('x', function (d) {
				return labelPos.centroid(d)[0];
			})
			.attr('y', function (d) {
				return labelPos.centroid(d)[1];
			})
			.attr("width", "34")
			.attr("height", "34");

		arcSvg.append("circle")
			.attr("cx", 17)
			.attr("cy", 17)
			.attr("r", 17)
			.style("fill", "#F8D662");

		arcSvg.append("text")
			.attr("x", function (d) {
				return 10
			})
			.attr("y", function (d) {
				return 25
			})
			.style('fill', 'black')
			.style("font-size", "24px")
			.style("font-weight", "bold")
			.text(function (d) {
				switch (d.data['id']) {
					case 'A':
						return "A";
					case 'B':
						return "B";
					case 'F':
						return "F";
					case 'H':
						return "H";
					case 'T':
						return "T";
					case 'C':
						return "C";
				}
			});
	};
};


export default exports;
