/**
 * @module unterwegs.list.BotanistService
 */

/**
 * The Botanist service uses the
 * womeninbotany backend to get botanists
 * @constructor
 * @param {angular.$http} $http Angular http service.
 * @ngInject
 * @ngdoc service
 * @ngname womeninbotanyBotanist
 */
const exports = function($http, womeninbotanyServerURL) {

	/**
	 * @type {angular.$http}
	 * @private
	 */
	this.http_ = $http;

	/**
	 * @type {string}
	 * @private
	 */
        this.baseURL_ = womeninbotanyServerURL.replace(/\/$/, '');

	/**
	 * @type {string}
	 * @private
	 */
	this.deathplace = "DEATHPLACE";
	/**
	 * @type {string}
	 * @private
	 */
	this.birthplace = "BIRTHPLACE";

	/**
	 * @export
	 */
	this.places = {
		[this.deathplace]: "deathplace",
		[this.birthplace]: "birthplace"
	};
};

exports.prototype.yearOfEvent = function () {

	const url = `${this.baseURL_}/botanist/timelinedata`;

	return this.http_.get(url).then(
		this.handleGetData_.bind(this),
		(message) => {
			console.log(message);
		}
	);
}

/**
 * @return {angular.$q.Promise} Promise.
 * @export
 */
exports.prototype.getList = function (
		filter, page = 1, rows = 10, offset = 0, orderBy = 'asc', sidx = 'familyname'
) {

	const url = `${this.baseURL_}/botanist/filtered`;

	var data = {
		page  : page,
		rows  : rows,
		offset: offset
	};

	if (filter !== null) {
		data['filter'] = filter;
	}

	data['sidx'] = sidx;
	data['sord'] = orderBy;

	return this.http_.post(url, data).then(
		this.handleGetData_.bind(this),
		(message) => {
			console.log(message);
		}
	);
};

/**
 * @return {angular.$q.Promise} Promise.
 * @export
 */
exports.prototype.getStatistics = function (
  filter, page = 1, rows = 10, offset = 0, orderBy = 'asc'
) {

	const url = `${this.baseURL_}/botanist/filtered/statistics`;

	var data = {};

	if (filter !== null) {
		data['filter'] = filter;
	}

	return this.http_.post(url, data).then(
		this.handleGetData_.bind(this),
		(message) => {
			console.log(message);
		}
	);
};

/**
 * @return {angular.$q.Promise} Promise.
 * @export
 */
exports.prototype.getTopEventPlaces = function (filter, eventplace) {
	const url = `${this.baseURL_}/botanist/filtered/top/${eventplace}`;

	var data = {};

	if (filter !== null) {
		data['filter'] = filter;
	}

	return this.http_.post(url, data).then(
		this.handleGetData_.bind(this),
		(message) => {
			console.log(message);
		}
	);
};

/**
 * @return {angular.$q.Promise} Promise.
 * @export
 */
exports.prototype.getBotanist = function (id) {

	const url = `${this.baseURL_}/botanist/${id}/json`;

	return this.http_.get(url).then((response) => {
										return response.data;
									},
									(message) => {
										console.log(message);
									}
	);
};

/**
 * @export
 */
exports.prototype.getBotanistPlace = function (id, place) {
	switch (place) {
		case this.places[this.birthplace]:
			place = this.places[this.birthplace];
			break;
		case this.places[this.deathplace]:
			place = this.places[this.deathplace];
			break;
	}

	const url = `${this.baseURL_}/botanist/${id}/${place}`;

	return this.http_.get(url).then((response) => {
										return response.data;
									},
									(message) => {
										console.log(message);
									}
	);
};

/**
 * @param {angular.$http.Response} resp Ajax response.
 * @return {womeninbotanyx.Data} The  object.
 * @private
 */
exports.prototype.handleGetData_ = function (resp) {
	return resp.data;
};


/**
 * @type {!angular.Module}
 */
exports.module = angular.module('womeninbotanyBotanistService', []);

exports.module.service('womeninbotanyBotanistService', exports);

export default exports;
