/**
 * @module womeninbotany.list.module
 */
import womeninbotanyListComponent from 'womeninbotany/list/component.js';
import womeninbotanyEntryComponent from 'womeninbotany/list/entryComponent.js';
import womeninbotanySortComponent from 'womeninbotany/list/sortComponent.js';

import 'womeninbotany/icons/icons.svg';

/**
 * @type {!angular.Module}
 */
const exports = angular.module('womeninbotanyListModule', [
  womeninbotanyListComponent.name,
  womeninbotanyEntryComponent.name,
  womeninbotanySortComponent.name,
]);


export default exports;
