/**
 * @module womeninbotany.map.module
 */
import womeninbotanySpreadComponent from 'womeninbotany/map/spreadComponent.js';
import womeninbotanyConnectionComponent from 'womeninbotany/map/connectionComponent.js';
import womeninbotanyTooltipComponent from 'womeninbotany/map/tooltipComponent.js';
import womeninbotanyInfoboxComponent from 'womeninbotany/map/infoboxComponent.js';

import './infobox.less';

/**
 * @type {!angular.Module}
 */
const exports = angular.module('womeninbotanyMapModule', [
  womeninbotanySpreadComponent.name,
  womeninbotanyConnectionComponent.name,
  womeninbotanyTooltipComponent.name,
  womeninbotanyInfoboxComponent.name,
]);

export default exports;
