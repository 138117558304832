/**
 * @module womeninbotany.sidebar.logoComponent
 */

/**
 * @type {!angular.Module}
 */
const exports = angular.module('womeninbotanySidebarLogo', [
]);


exports.run(/* @ngInject */ ($templateCache) => {
  $templateCache.put('womeninbotany/sidebar/logoComponent', require('./logo.html'));
});


exports.component_ = {
  controller:  'WomeninbotanySidebarLogoController',
  bindings: {
    'infopageShown': '=womeninbotanyInfopageShown',
    'reportpageShown': '=womeninbotanyReportpageShown'
  },
  templateUrl: 'womeninbotany/sidebar/logoComponent'
};

exports.component('womeninbotanySidebarLogo', exports.component_);


/**
 * @constructor
 * @export
 * @ngInject
 * @ngdoc Controller
 * @ngname WomeninbotanySidebarLogoController
 */
exports.Controller_ = function() {

  /**
   * @type {boolean}
   * @export
   */
  this.infopageShown;

  /**
   * @type {boolean}
   * @export
   */
  this.reportpageShown;

};


exports.controller('WomeninbotanySidebarLogoController', exports.Controller_);


export default exports;
