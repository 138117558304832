/**
 * @module womeninbotany.reportpage.component
 */

import ngeoMessageModalComponent from 'ngeo/message/modalComponent.js';
import womeninbotanyEntryField from 'womeninbotany/list/entryFieldComponent.js';
import womeninbotanyEmailService from 'womeninbotany/utility/EmailService.js';

/**
 * @type {!angular.Module}
 */
const exports = angular.module('womeninbotanyReportPage', [
	ngeoMessageModalComponent.name,
	womeninbotanyEntryField.name,
	womeninbotanyEmailService.module.name
]);

exports.run(/* @ngInject */ ($templateCache) => {
  $templateCache.put('womeninbotany/reportpage/component', require('./component.html'));
});


exports.component_ = {
	controller : 'womeninbotanyReportpageController',
	bindings   : {
		'reportpageShown': '=womeninbotanyReportpageShown'
	},
	templateUrl: 'womeninbotany/reportpage/component'
};

exports.component('womeninbotanyReportPage', exports.component_);

/**
 * @param {angular.Scope} $rootScope The rootScope provider.
 * @param {womeninbotany.Email} womeninbotanyEmail service
 * @constructor
 * @export
 * @ngInject
 * @ngdoc Controller
 * @ngname WomeninbotanyReportpageController
 */
exports.Controller_ = function ($scope, womeninbotanyEmailService) {
	/**
	 * @private
	 */
	this.womeninbotanyEmailService_ = womeninbotanyEmailService;

	/**
	 * @export
	 */
	this.reportpageShown;

	/**
	 * @export
	 */
	this.state = 0;

	/**
	 * @export
	 */
	this.formSend = false;

	/**
	 * @export
	 */
	this.formsCount;

	/**
	 * @export
	 */
	this.addedFieldsContainerStyle = {
		'left': 0
	};

	/**
	 * @export
	 */
	this.models = {
		surname: '',
		forename: '',
		birthDate: '',
		deathDate: '',
		birthPlace: '',
		deathPlace: '',
		education: '',
		experience: '',
		work: '',
		honors: '',
		maritalStatus: '',
		remarks: '',
		notes: '',
		references: [''],
		links: ['']
	};

	/**
	 * @export
	 */
	this.email = '';

	/**
	 * @export
	 */
	this.references = [''];

	/**
	 * @export
	 */
	this.links = [''];

	/**
	 * @export
	 */
	this.alert = {
		isVisible: false,
		type: 'success',
		msgTemplates: {
			success: `An email to the administration has been successfully sent!`,
			error: 'An error occured.'
		},
		msg: ''
	};

	$scope.$watch(function () {
			return this.reportpageShown;
		}.bind(this),
    function (newValue, oldValue) {
	    if (newValue) {
		    const addedFieldsContainer = document.querySelector('.report-page-added-fields-container');
		    this.formsCount = addedFieldsContainer.querySelectorAll('.report-page-added-fields').length;
	    } else {
	    	this.reset_();
	    }
	}.bind(this), true);

	// workaround as filters don't work on ng-model and ng-change doesn't pass the input element
	$scope.$watchCollection(function () {
    return this.models;
  }.bind(this),
  function (newValue, oldValue) {
    if (newValue) {
      this.models.birthDate = newValue.birthDate.replace(/[^0-9\.]/g, '');
    }
  }.bind(this), true);

	// workaround as filters don't work on ng-model and ng-change doesn't pass the input element
	$scope.$watchCollection(function () {
    return this.models;
  }.bind(this),
  function (newValue, oldValue) {
    if (newValue) {
      this.models.deathDate = newValue.deathDate.replace(/[^0-9\.]/g, '');
    }
  }.bind(this), true);
};

/**
 * @export
 */
exports.Controller_.prototype.nextForm = function(newEntryForm) {
	if (this.state >= (this.formsCount - 1)) {
		this.sendForm(newEntryForm);
		return;
	}

	if (this.state < (this.formsCount - 1)) {
		this.state++;
	}
	this.animateForm_();
};

/**
 * @export
 */
exports.Controller_.prototype.prevForm = function() {
	if (this.state <= 0) {
		this.reportpageShown = false;
		return;
	}

	if (this.state > 0) {
		this.state--;
	}
	this.animateForm_();
};

/**
 * @private
 */
exports.Controller_.prototype.animateForm_ = function() {
	this.addedFieldsContainerStyle = {
		'left': `-${this.state}00%`
	};
};

/**
 * @export
 */
exports.Controller_.prototype.addReference = function() {
	this.models.references.push('');
};

/**
 * @export
 */
exports.Controller_.prototype.addLink = function() {
	this.models.links.push('');
};

/**
 * @export
 */
exports.Controller_.prototype.sendForm = function(newEntryForm) {
	if (!this.formSend) {
		if (newEntryForm.email.$valid) {

			this.womeninbotanyEmailService_.sendEmail({
				botanist: this.models,
        email: this.email
			});

			this.alert.isVisible = true;
			this.alert.msg = this.alert.msgTemplates.success;

			this.formSend = true;
		} else {
			this.alert.isVisible = false;
		}
	}
};

/**
 * @export
 */
exports.Controller_.prototype.closeAlert = function() {
	this.alert.isVisible = false;
};

/**
 * @private
 */
exports.Controller_.prototype.reset_ = function() {
	this.state = 0;
	this.formSend = false;

	for (const [key, value] of Object.entries(this.models)) {
		if (Array.isArray(this.models[`${key}`])) {
			this.models[`${key}`] = [''];
		} else {
			this.models[`${key}`] = '';
		}
	}
	this.email = '';

	this.closeAlert();
	this.animateForm_();
};

exports.controller('womeninbotanyReportpageController', exports.Controller_);

export default exports;
