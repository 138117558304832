/**
 * @module womeninbotany.statistics.component
 */

import womeninbotanyBotanistService from 'womeninbotany/list/BotanistService.js';
import womeninbotanyStatisticsPieChartComponent from 'womeninbotany/statistics/piechartComponent.js';

/**
 * @type {!angular.Module}
 */
const exports = angular.module('womeninbotanyStatistics', [
  womeninbotanyBotanistService.module.name,
  womeninbotanyStatisticsPieChartComponent.name,
]);

exports.run(/* @ngInject */ ($templateCache) => {
  $templateCache.put('womeninbotany/statistics/component', require('./component.html'));
});


exports.component_ = {
  controller: 'womeninbotanyStatisticsController',
  bindings: {
    'filter': '=womeninbotanyStatisticsFilter'
  },
  templateUrl: 'womeninbotany/statistics/component'
};

exports.component('womeninbotanyStatistics', exports.component_);

/**
 * @param {womeninbotany.Botanist} womeninbotanyBotanist service
 * @constructor
 * @ngInject
 * @ngdoc controller
 * @ngname ListController
 */
exports.Controller_ = function($scope, womeninbotanyBotanistService) {

  /**
   * @private
   */
  this.womeninbotanyBotanist_ = womeninbotanyBotanistService;

  /**
   * @export
   */
  this.categories;

  /**
   * @export
   */
  this.topBirthplaces;

  /**
   * @export
   */
  this.topDeathplaces;

  /**
   * @export
   */
  this.categoriesOptions = {color: 'blue'};

  $scope.$watch(
    function() {
      return this.filter;
    }.bind(this),
    function(newValue, oldValue) {
      this.updateStatistics_();
    }.bind(this),true);
};

/**
 * @private
 */
exports.Controller_.prototype.updateStatistics_ = function() {
  this.womeninbotanyBotanist_.getStatistics(this.filter).then(function(data) {
    this.categories = [];
    data['records'].forEach(function(category) {
      this.categories.push(category);
    }, this);
  }.bind(this));
  this.womeninbotanyBotanist_.getTopEventPlaces(this.filter, 'birthplace').then(function(data) {
    this.topBirthplaces = [];
    data['records'].forEach(function(birthplace) {
      this.topBirthplaces.push(birthplace);
    }, this);
  }.bind(this));
  this.womeninbotanyBotanist_.getTopEventPlaces(this.filter, 'deathplace').then(function(data) {
    this.topDeathplaces = [];
    data['records'].forEach(function(deathplace) {
      this.topDeathplaces.push(deathplace);
    }, this);
  }.bind(this));
};


exports.controller('womeninbotanyStatisticsController', exports.Controller_);

export default exports;
