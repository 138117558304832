/**
 * @module womeninbotany.list.entryComponent
 */


import womeninbotanyBotanistService from 'womeninbotany/list/BotanistService.js';
import womeninbotanyMiscScrolling from 'womeninbotany/misc/ScrollingService.js';
import womeninbotanyEntryField from 'womeninbotany/list/entryFieldComponent.js';
import womeninbotanyEmailService from 'womeninbotany/utility/EmailService.js';


/**
 * @type {!angular.Module}
 */
const exports = angular.module('womeninbotanyEntry', [
  womeninbotanyBotanistService.module.name,
  womeninbotanyMiscScrolling.module.name,
  womeninbotanyEntryField.name,
  womeninbotanyEmailService.module.name
]);

exports.run(/* @ngInject */ ($templateCache) => {
  $templateCache.put('womeninbotany/list/entryComponent', require('./entry.html'));
});


exports.component_ = {
  controller: 'womeninbotanyListEntryController',
  bindings: {
    botanist : '=',
    showOnMapFunction: '&',
    filter: '=',
  },
  templateUrl: 'womeninbotany/list/entryComponent'
};

exports.component('womeninbotanyListEntry', exports.component_);

/**
 * @constructor
 * @export
 * @ngInject
 * @ngdoc Controller
 * @ngname WomeninbotanyListEntryController
 */
exports.Controller_ = function ($scope, $element, $attrs, $sce,
  womeninbotanyBotanistService,
  womeninbotanyScrollingService,
  womeninbotanyEmailService
) {
  /**
   * @private
   */
  this.womeninbotanyBotanist_ = womeninbotanyBotanistService;

  /**
   * @private
   */
  this.womeninbotanyScrolling_ = womeninbotanyScrollingService;

  /**
   * @private
   */
  this.womeninbotanyEmailService_ = womeninbotanyEmailService;

  /**
   * @private
   */
  this.element_ = $element;
  /**
   * @private
   */
  this.sce_ = $sce;
  /**
   * @private
   */
  this.prevBotanistValues_ = null;

  /**
   * @export
   */
  this.places = this.womeninbotanyBotanist_.places;

  /**
   * @export
   */
  // this.coordinates = null;

  /**
   * @export
   */
  this.botanist;

  /**
   * @export
   */
  this.filter;

  /**
   * @export
   */
  this.botanistFields = [];

  /**
   * @export
   */
  this.showOnMap;

  /**
   * @export
   */
  this.isCollapsed = true;

  /**
   * @private
   */
  this.loaded_ = false;

  /**
   * @export
   */
  this.isEditingActive = false;

  /**
   * @export
   */
  this.addedFields = [];

  /**
   * @export
   */
  this.email = '';

	/**
	 * @export
	 */
	this.alert = {
		isVisible: false,
		type: 'success',
		msgTemplates: {
			success: `An email to the administration has successfully been sent!`,
			error: 'An error occured.'
		},
		msg: ''
	};

  // workaround as filters don't work on ng-model and ng-change doesn't pass the input element
  $scope.$watch(function () {
      return this.botanist.year_of_birth;
    }.bind(this),
    function (newValue, oldValue) {
      if (newValue && typeof newValue == "string") {
        this.botanist.year_of_birth = newValue.replace(/[^0-9\.]/g, '');
      }
    }.bind(this), true);

  // workaround as filters don't work on ng-model and ng-change doesn't pass the input element
  $scope.$watch(function () {
    return this.botanist.year_of_death;
  }.bind(this),
  function (newValue, oldValue) {
    if (newValue && typeof newValue == "string") {
      this.botanist.year_of_death = newValue.replace(/[^0-9\.]/g, '');
    }
  }.bind(this), true);

  // check if botanist had changed
  $scope.$watch(function () {
    return [this.filter];
  }.bind(this),
  function (newValue, oldValue) {
        if (!angular.equals(newValue[0], oldValue[0])) {
      this.loaded_ = false;
      this.isCollapsed = true;
    } else { }
  }.bind(this), true);

};

/**
 * @export
 */
exports.Controller_.prototype.placeClicked = function (place) {
  console.log('Place: ', place);
  /* is wrong! coordiantes are used without checking if place has changed */
  // if (Array.isArray(this.coordinates)) {
  //   console.log('this.coordinates: ', this.coordinates);
  //   if (this.coordinates.length > 0) {
  //     this.showOnMapFunction({longitude: this.coordinates[0], latitude: this.coordinates[1]});
  //     this.womeninbotanyScrolling_.scrollToTop();
  //   }
  //   else {
  //     angular.element(this.element_ [0].querySelector('.tooltip-popover')).addClass('tooltip-popover--active');
  //   }
  // }
  // else {
    console.log('botanist: ', this.botanist.id);

    this.womeninbotanyBotanist_.getBotanistPlace(this.botanist.id, place).then((data) => {
      if (data && data.coordinates && data.coordinates.length > 0) {
        const coordinates = data.coordinates;
        // this.coordinates  = coordinates;
        this.showOnMapFunction({longitude: coordinates[0], latitude: coordinates[1]});
        this.womeninbotanyScrolling_.scrollToTop();
      }
      else {
        angular.element(this.element_ [0].querySelector('.tooltip-popover')).addClass('tooltip-popover--active');
      }
    });
  // }
};

/**
 * @export
 */
exports.Controller_.prototype.onDetailsClicked = function (id) {
  if (this.loaded_) {
    this.isCollapsed = !this.isCollapsed;
  }
  else {
    this.womeninbotanyBotanist_.getBotanist(id).then(function (botanist) {
      if (botanist) {
        this.botanist['links']      = botanist['links'];
        this.botanist['references'] = botanist['references'];
        // escape custom tags
        angular.forEach(this.botanist, (value, key, obj) => {
          if (typeof value === 'string' || value instanceof String) {
            obj[key] = this.sce_.trustAsHtml(value);
          }
        });
      }
      else {
        // TODO
      }
      this.loaded_   = true;
      this.isCollapsed = !this.isCollapsed;
    }.bind(this));
  }
  if (this.isCollapsed) {
    this.isEditingActive = false;
  }
};

/**
 * @export
 */
exports.Controller_.prototype.editEntry = function(id) {
  this.isEditingActive = !this.isEditingActive;
	this.alert.isVisible = false;

  if (this.isEditingActive) {
    this.prevBotanistValues_ = Object.assign({}, this.botanist);
  } else {
    // cancel editing
    this.addedFields = [];
    this.botanist = Object.assign({}, this.prevBotanistValues_);

    this.email = '';
  }
};

/**
 * @export
 */
exports.Controller_.prototype.highlightSearchTerms = function (text) {
  if (text != null) {
    // text = this.sce_.valueOf(text);
    text = text.toString();
    if (this.filter.searchTerms.length > 0) {
	    this.filter.searchTerms.forEach((element, index, array) => {
		    text = text.replace(new RegExp(element, 'gi'), '<span class="highlighted-text">$&</span>')
	    })

    }
    return this.sce_.trustAsHtml(text);
  }
  return text;
};

/**
 * @export
 */
exports.Controller_.prototype.addField = function(id) {
  this.botanistFields = this.getBotanistFields_();
  this.addedFields.push({
    id: this.addedFields.length
  });
};

/**
 * @export
 */
exports.Controller_.prototype.deleteField = function(id) {
  this.addedFields = this.addedFields.filter((item, index) => {
    return item.id != id;
  });
};

/**
 * @private
 */
exports.Controller_.prototype.getBotanistFields_ = function() {
  let options = [];
  for (const [key, value] of Object.entries(this.botanist)) {
    // TODO: filter appropriate fields
	  if (key == 'firstname' || key == 'familyname' || key == 'birthname') {
	  	continue;
	  }

    if (value == null || Array.isArray(value)) {
      const selectOptionText = key.replace(/_/gi, ' ');
      options.push(selectOptionText);
    }
  }
  return options;
};

/**
 * @export
 */
exports.Controller_.prototype.saveEditing = function(emailForm) {
  if (emailForm.email.$valid) {
    const addedFields = [];

    for (const addedField of this.addedFields) {
      if (addedField.fieldOption && addedField.content) {
        addedFields.push(addedField)
      }
    }

    const editedEntry = {
      botanist: this.botanist,
      addedFields,
      email: this.email
    }

    this.womeninbotanyEmailService_.sendEmail(editedEntry)

    this.isEditingActive = false;
    this.addedFields     = [];

    this.email = '';

    this.alert.msg       = this.alert.msgTemplates.success;
    this.alert.isVisible = true;
  }
};

/**
 * @export
 */
exports.Controller_.prototype.closeAlert = function(id) {
	this.alert.isVisible = false;
};

exports.controller('womeninbotanyListEntryController', exports.Controller_);

export default exports;
