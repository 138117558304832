/**
 * @module womeninbotany.sidebar.module
 */
import womeninbotanySidebarFilterComponent from 'womeninbotany/sidebar/filterComponent.js';
import womeninbotanySidebarLogoComponent from 'womeninbotany/sidebar/logoComponent.js';

import './simple-sidebar.less';

/**
 * @type {!angular.Module}
 */
const exports = angular.module('womeninbotanySidebarModule', [
  womeninbotanySidebarFilterComponent.name,
  womeninbotanySidebarLogoComponent.name,
]);


export default exports;
