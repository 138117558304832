/**
 * @module womeninbotany.sidebar.filterComponent
 */

import womeninbotanyGeonameService from 'womeninbotany/sidebar/GeonameService.js';
import womeninbotanyPlantService from 'womeninbotany/sidebar/PlantService.js';
import womeninbotanyUtilityUpdateOnEnterComponent from 'womeninbotany/utility/updateOnEnterComponent.js';

import 'angular-ui-bootstrap';

/**
 * @type {!angular.Module}
 */
const exports = angular.module('womeninbotanyFilter', [
  womeninbotanyGeonameService.module.name,
  womeninbotanyPlantService.module.name,
  womeninbotanyUtilityUpdateOnEnterComponent.name,
  'ui.bootstrap',
]);

exports.run(/* @ngInject */ ($templateCache) => {
  $templateCache.put('womeninbotany/sidebar/filterComponent', require('./filter.html'));
});


exports.component_ = {
  controller: 'womeninbotanyFilterController',
  bindings: {
    'filterRules': '=womeninbotanyFilterRules',
    'maxDates': '=womeninbotanyMaxDates',
    'clearFilters': '&womeninbotanyClearFilters',
    'botanistsTotal': '=womeninbotanyBotanistsTotal',
    'botanistsOnMap': '=womeninbotanyBotanistsOnMap'
  },
  templateUrl: 'womeninbotany/sidebar/filterComponent'
};

exports.component('womeninbotanyFilter', exports.component_);


/**
 * @param {angular.Scope} $scope Angular scope.
 * @param {womeninbotany.Geoname} womeninbotanyGeoname service
 * @param {womeninbotany.PlantService} womeninbotanyPlantService service
 * @constructor
 * @export
 * @ngInject
 * @ngdoc Controller
 * @ngname WomeninbotanyFilterController
 */
exports.Controller_ = function($scope, womeninbotanyGeonameService, womeninbotanyPlantService) {

  /**
   * @type {womeninbotanyx.FilterRules}
   * @export
   */
  this.filterRules;

  /**
   * @export
   */
  this.botanistsTotal;

  /**
   * @export
   */
  this.botanistsOnMap;

	/**
	 * @export
	 */
	this.placeName;

	/**
	 * @export
	 */
	this.plantName;

  /**
   * @private
   */
  this.womeninbotanyGeoname_ = womeninbotanyGeonameService;

  /**
   * @private
   */
  this.womeninbotanyPlant_ = womeninbotanyPlantService;

  /**
   * @export
   */
  this.filterPlace; // method to update the filter rule according to the input place

  /**
   * @type {Array.<number>}
   * @export
   */
  this.maxDates;

  /**
   * @export
   */
  this.clearFilters;

  this.geoname_objects = [];

  this.plant_objects = [];

	/**
	 * @export
	 */
	this.categoriesShown = false;
};

/**
 * @export
 */
exports.Controller_.prototype.onBlur = function(whichFilter) {
	if (whichFilter == 'place') {
		if (this.placeName == undefined || this.placeName.length < 1) {
			this.filterRules["place"] = "";
		} else {
			const isPlaceInAutocomplete = this.geoname_objects.find((element) => {
				return element.name == this.placeName;
			})

			if (!isPlaceInAutocomplete) {
				// TODO: update botanistList to contain zero elements instead of requesting empty data from backend
				this.filterRules["place"] = ""
			}
		}
	} else if (whichFilter == 'plant') {
		if (this.plantName == undefined || this.plantName.length < 1) {
			this.filterRules["plant"] = "";
			this.filterRules["plantName"] = "";
		} else {
			const isPlantInAutocomplete = this.plant_objects.find((element) => {
				return element.name == this.plantName;
			})

			if (!isPlantInAutocomplete) {
				// TODO: update botanistList to contain zero elements instead of requesting empty data from backend
				this.filterRules["plant"] = ""
				this.filterRules["plantName"] = "";
			}
		}
	}
};


/**
 * @export
 */
exports.Controller_.prototype.getLocation = function(search) {
  return this.womeninbotanyGeoname_.searchNames(search).then((data) => {
    this.geoname_objects = data['geonames'];
    return this.geoname_objects;
  });
};


/**
 * @export
 */
exports.Controller_.prototype.getPlant = function(search) {
  return this.womeninbotanyPlant_.searchNames(search).then((data) => {
    this.plant_objects = data['species'];
    return this.plant_objects;
  });
};


/**
 * @export
 */
exports.Controller_.prototype.selectPlace = function(item) {
	if (this.filterRules["place"] != item.id) {
		this.filterRules["place"] = item.id;
	}
};


/**
 * @export
 */
exports.Controller_.prototype.selectPlant = function(item) {
	if (this.filterRules["plant"] != item.id) {
		this.filterRules["plant"] = item.id;
		this.filterRules["plantName"] = item.name;
	}
};

/**
 * @export
 */
exports.Controller_.prototype.clearModels = function() {
	this.placeName = "";
	this.plantName = "";
};

/**
 * @export
 */
exports.Controller_.prototype.applyFilters = function() {
	// actually useless as the filter gets updated when the inputs lose focus
	// which happens when anything is clicked including this button
};


exports.controller('womeninbotanyFilterController', exports.Controller_);

export default exports;
