/**
 * @module womeninbotany.map.infoboxComponent
 */

import googAsserts from 'goog/asserts.js';
import olMap from 'ol/Map.js';
import * as olEvents from 'ol/events.js';
import womeninbotanyBotanistService from 'womeninbotany/list/BotanistService.js';
import womeninbotanyMiscScrolling from 'womeninbotany/misc/ScrollingService.js';

/**
 * @type {!angular.Module}
 */
const exports = angular.module('womeninbotanyInfobox', [
  womeninbotanyBotanistService.module.name,
  womeninbotanyMiscScrolling.module.name,
]);

exports.run(/* @ngInject */ ($templateCache) => {
  $templateCache.put('womeninbotany/map/infoboxComponent', require('./infobox.html'));
});


exports.component_ = {
  controller: 'womeninbotanyMapInfoboxController',
  bindings: {
    'getMapFn'    : '&womeninbotanyInfoboxMap'
  },
  templateUrl: 'womeninbotany/map/infoboxComponent'
};

exports.component('womeninbotanyInfobox', exports.component_);

/**
 * @param {womeninbotany.Botanist} womeninbotanyBotanistService womeninbotanyBotanist service
 * @param {angular.Scope} $rootScope The rootScope provider.
 * @constructor
 * @export
 * @ngInject
 * @ngdoc Controller
 * @ngname WomeninbotanyInfoboxController
 */
exports.Controller_ = function ($scope, $rootScope, womeninbotanyBotanistService, womeninbotanyScrollingService, $sce) {
  /**
   * @private
   */
  this.HIT_TOLERANCE = 4;

  /**
   * The scope.
   * @type {angular.Scope}
   * @private
   */
  this.scope_ = $scope;

  /**
   * The rootScope.
   * @type {angular.Scope}
   * @private
   */
  this.rootScope_ = $rootScope;

  /**
   * @type {boolean}
   * @export
   */
  this.isInfoBoxVisible = false;

  /**
   * @type {Object}
   * @export
   */
  this.botanist;

  /**
   * @private
   */
  this.womeninbotanyBotanist_ = womeninbotanyBotanistService;

  /**
   * @private
   */
  this.womeninbotanyScrolling_ = womeninbotanyScrollingService;

  /**
   * @type {Array<number>}
   * @export
   */
  this.botanists;

  /**
   * @type {number}
   * @export
   */
  this.botanistIndex;

  // wait until constructor has done its initialization

  this.$onInit = function () {
    const map = this['getMapFn']();
    googAsserts.assertInstanceof(map, olMap);

    olEvents.listen(
      map, 'singleclick', this.handleClick_, this
    );
  };

  this.sce_ = $sce;

};


/**
 * @private
 */
exports.Controller_.prototype.collectBotanists = function(feature) {
  const id = feature.get('id');
  if (this.botanists.indexOf(id) === -1) {  // for uniqueness
    this.botanists.push(id);
  }
}


/**
 * @private
 */
exports.Controller_.prototype.handleClick_ = function (evt) {
  this.botanists     = [];
  this.botanistIndex = 0;
  if (this.isInfoBoxVisible) {
    this.isInfoBoxVisible = false;
    this.scope_.$applyAsync();
  }
  let found = false;
  evt.map.forEachLayerAtPixel(evt.pixel, (layer) => {
    let layers = evt.map.getLayers();
    layers.forEach(function(layer){
      if (layer.get('name') === 'spreaded-points') {
        let source = layer.getSource();
        if (source.getFeatures().length > 0) {
            source.forEachFeature(this.collectBotanists.bind(this));
            found = true;
        }
      }
    }.bind(this));
  }, this,
    function (layer) {
      return layer.get('name') === 'circle-of-spread';
    }
  );
  if (!found) {
    evt.map.forEachFeatureAtPixel(evt.pixel, this.collectBotanists.bind(this),
    {
      layerFilter: function (layer) {
        return layer.get('name') === 'places';
      }
      // hitTolerance: this.HIT_TOLERANCE
    });
  }
  if (this.botanists.length > 0) {
    this.showInfo_();
  }
};

/**
 * @private
 */
exports.Controller_.prototype.showInfo_ = function () {
  this.womeninbotanyScrolling_.scrollToTop();
  this.isInfoBoxVisible = true;
  if (this.botanists.length > 0) {
    this.showBotanist_();
  }
  else {
    this.showError_();
  }
};

/**
 * @private
 */
exports.Controller_.prototype.showError_ = function () {
  this.botanist = null;
};

/**
 * @private
 */
exports.Controller_.prototype.showBotanist_ = function () {
  const id = this.botanists[this.botanistIndex];
  this.womeninbotanyBotanist_.getBotanist(id).then(function (botanist) {
    if (botanist) {
      this.botanist = botanist;
      angular.forEach(this.botanist, (value, key, obj) => {
        if (typeof value === 'string' || value instanceof String) {
		        obj[key] = this.sce_.trustAsHtml(value);
        }
      });
    } else {
      this.showError_();
    }
  }.bind(this));
};

/**
 * @export
 */
exports.Controller_.prototype.changePage = function (direction) {
  if (direction === 'next') {
    if (this.botanistIndex < this.botanists.length - 1) {
      this.botanistIndex++;
      this.showBotanist_();
    }
  }
  else if (direction === 'prev') {
    if (this.botanistIndex > 0) {
      this.botanistIndex--;
      this.showBotanist_();
    }
  }
};


exports.controller('womeninbotanyMapInfoboxController', exports.Controller_);

export default exports;
