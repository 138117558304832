/**
 * @module womeninbotany.map.connectionComponent
 */

import googAsserts from 'goog/asserts.js';
import olSourceVector from 'ol/source/Vector.js';
import olLayerVector from 'ol/layer/Vector.js';
import olStyleStyle from 'ol/style/Style.js';
import olStyleFill from 'ol/style/Fill.js';
import olStyleStroke from 'ol/style/Stroke.js';
import olMap from 'ol/Map.js';
import * as olEvents from 'ol/events.js';
import womeninbotanyPlaceService from 'womeninbotany/map/PlaceService.js';

/**
 * @type {!angular.Module}
 */
const exports = angular.module('womeninbotanyConnection', [
  womeninbotanyPlaceService.module.name
]);

exports.component_ = {
  controller: 'womeninbotanyMapConnectionController',
  bindings: {
    'getMapFn'    : '&womeninbotanyConnectionMap',
    'filterRules': '=womeninbotanyConnectionFilterRules'
  }
};

exports.component('womeninbotanyConnection', exports.component_);

/**
 * @param {womeninbotany.Place} womeninbotanyPlace service
 * @constructor
 * @export
 * @ngInject
 * @ngdoc Controller
 * @ngname WomeninbotanyConnectionController
 */
exports.Controller_ = function ($scope, womeninbotanyPlaceService) {

	this.scope = $scope;

  /**
   * @private
   */
  this.womeninbotanyPlace_ = womeninbotanyPlaceService;

  /**
   * @type {womeninbotanyx.FilterRules}
   * @export
   */
  this.filterRules;

  /**
   * @type {number}
   * @private
   */
  this.selectedPlaceId_;

  /**
   * @type {ol.source.Vector}
   * @private
   */
  this.connectionSource_ = new olSourceVector({
    features: [],
    wrapX : false
  });

  this.connectionStyle_ = new olStyleStyle({
    fill  : new olStyleFill({
      color: 'blue'
    }),
    stroke: new olStyleStroke({
      color: '#3399CC',
      width: 1.25
    })
  });

  /**
   * @type {ol.layer.Vector}
   * @private
   */
  this.connectionLayer_ = new olLayerVector({
    name          : 'connections',
    source          : this.connectionSource_,
    style         : this.connectionStyle_,
    updateWhileAnimating  : true,
    updateWhileInteracting: true
  });

  // wait until constructor has done its initialization

  this.$onInit = function () {
    const map = this['getMapFn']();
    googAsserts.assertInstanceof(map, olMap);

    olEvents.listen(map, 'pointermove', this.handlePointerMove_, this);

    map.addLayer(this.connectionLayer_);
  };
};

exports.Controller_.prototype.handlePointerMove_ = function (evt) {
  let features = [];
  evt.map.forEachFeatureAtPixel(evt.pixel, function (feature) {
    if (feature.getGeometry().getType() === 'Point') {
      features.push(feature);
    }
  });
  if (features.length === 1) {
    let place_id = features[0].get('place_id');
    if (place_id) {
      if (this.selectedPlaceId_ === place_id) {
        return;
      } else {
        this.selectedPlaceId_ = place_id
      }
      this.womeninbotanyPlace_.getPairOfPlaces(
        place_id, this.filterRules
      ).then(function (features) {
        this.connectionSource_.clear();
        if (features != null) {
          this.connectionSource_.addFeatures(features);
        }
      }.bind(this));
    }
  }

	this.scope.$watch(
		function () {
			return [this.filterRules];
		}.bind(this),
		function (newValue, oldValue) {
			if (!angular.equals(newValue[0], oldValue[0])) {
				this.connectionSource_.clear();
			}
			else {
			}
		}.bind(this), true);
};


exports.controller('womeninbotanyMapConnectionController', exports.Controller_);

export default exports;
