/**
 * @module womeninbotany.utility.updateOnEnterComponent
 */

/**
 * @type {!angular.Module}
 */
const exports = angular.module('womeninbotanyUpdateOnEnter', [
]);


/**
 * Provides a directive for updating a model when pressing enter
 *
 * @return {angular.Directive} Directive Definition Object.
 * @ngInject
 * @ngdoc directive
 * @ngname womeninbotanyuUpdateOnEnterDirective
 */
exports.directive_ = function () {
  return {
    restrict: 'A',
    require : 'ngModel',
     /**
     * The directive enables users to submit their input by pressing enter.
     * @param scope
     * @param element
     * @param attr
     * @param ctrl
     */
    link    : function (scope, element, attr, ctrl) {
      /*
       When a key is released the function checks if it was the enter key.
       */
      element.on("keyup", function (event) {
        /*
         If it was the enter key, the value is committed to the model.
         */
        if (event.keyCode === 13) {
          ctrl.$commitViewValue();
        }
        /*
         If any other was pressed, nothing should happen
         */

        else {
          if (!element.hasClass('filter-element--location')) {
            return event.stopImmediatePropagation();
          }
        }
      });
    }
  }
};

exports.directive('womeninbotanyUpdateOnEnter', exports.directive_);

export default exports;
