/**
 * @module womeninbotany.timeline.d3Timeline
 */

import {extent, min, max} from 'd3-array';
import {event} from 'd3-selection';
import {scaleLinear, scaleTime} from 'd3-scale';
import {timeParse, timeFormat} from 'd3-time-format';
import {brushX} from 'd3-brush';




const d3 = {
  extent,
  event,
  min,
  max,
  scaleLinear,
  scaleTime,
  timeParse,
  timeFormat,
  brushX,
}

const exports = function (options) {

  /**
   *  time formated as 4-digit year
   */
  const yearParser        = d3.timeParse('%Y');
  const yearFormatter     = d3.timeFormat('%Y');
  const yearLabels        = {};
  const yearLabelPadding  = 2;
  const timelineSeparator = 1700;

  const height = 100;
  let width;
  let x, y, yearMin, yearMax;
  let svg, brush;
  let birthBar, deathBar, barGroup;
  let data;
  let barWidth;
  let buttonLeft, buttonRight;

  /**
   * maxExtent callback function.
   * @type {function(Array)}
   */
  const maxExtentCallback = options.maxExtentCallback !== undefined ?
    options.maxExtentCallback : ol.nullFunction;

  /**
   * setDatesCallback callback function.
   * @type {function(Array)}
   */
  const setDatesCallback = options.setDatesCallback !== undefined ?
    options.setDatesCallback : ol.nullFunction;

  const timeline = function (element) {
    element.selectAll('*').remove();
    data = element.datum();
    if (data === undefined) {
      return;
    }

    buttonLeft  = element.append('button')
      .classed('timelineButton-left btn btn-default glyphicon glyphicon-chevron-left', true);
    buttonRight = element.append('button')
      .classed('timelineButton-right btn btn-default glyphicon glyphicon-chevron-right', true);

    const frame = element.append('div')
      .classed('timeline', true)
      .append('div')
      .attr('id', 'brush')
      .classed('class', 'ui-brush', true);

    const headline  = frame.append('div')
      .html('Timeline of <span class="timeline-headline--green" >Births</span> and <span class="timeline-headline--red">Deaths</span>')
      .classed('timeline__headline', true)
      .attr("width", '100%')
      .attr('height', '15px')


    // append the svg object to the selected element
    // append a 'group' element to 'svg'
    // moves the 'group' element to the top left margin
    svg = frame.append('svg')
      .attr("width", '100%')
      .attr('height', height)
      .append('g')

    width = frame.node().getBoundingClientRect().width;
    // set the ranges
    x     = d3.scaleTime().range([0, width]);
    y     = d3.scaleLinear().range([height, 0]);

    brush = d3.brushX()
      .extent([[0, 0], [width, height]])
      .on('brush', timeline.brushed)
      .on('end', timeline.brushended);

    // format the year as a date
    data.forEach(function (d) {
      d.year = yearParser(d.year);
    });

    const yearExtent = d3.extent(data, function (d) {
      return d.year;
    });
    yearMin          = yearExtent[0];
    yearMax          = yearExtent[1];

    maxExtentCallback([yearFormatter(yearMin), yearFormatter(yearMax)]);

    const ymin = d3.min(data, function (d) {
      return -d.death;
    });
    const ymax = d3.max(data, function (d) {
      return d.birth;
    });

    y.domain([ymin, ymax]);

    barGroup = svg.append('g')
      .classed('bars', true);

    timeline.addBars('right');

    // add brushing
    const gBrush = svg.append('g')
      .classed('brush', true)
      .call(brush);

    gBrush.append('rect')
      .classed('year-label-border e', true);

    gBrush.append("text")
      .classed('year-label-text e', true);

    gBrush.append('rect')
      .classed('year-label-border w', true);

    gBrush.append("text")
      .classed('year-label-text w', true);

    yearLabels['text'] = gBrush.selectAll('.year-label-text')
      .data([{type: "w"}, {type: "e"}])
      .attr('text-anchor', 'middle');

    yearLabels['border'] = gBrush.selectAll('.year-label-border')
      .data([{type: "w"}, {type: "e"}]);

  };

  timeline.brushed = function () {
    const d3Event = require("d3-selection").event;
    if (!d3Event || !d3Event.selection) return; // Ignore empty selections.
    var s   = d3Event.selection;
    var ext = s.map(function (p) {
      return yearFormatter(x.invert(p));
    });

    yearLabels['text']
      .attr('display', null)
      .text(function (d, i) {
        return ext[i];
      })
      .attr('transform', function (d, i) {
        return 'translate(' + s[i] + ',' + 20 + ')';
      });

    const bboxes = yearLabels['text'].nodes().map(function (node) {
      return node.getBBox();
    });

    const padding = yearLabelPadding;
    var translate = function (i) {
      return 'translate(' + (s[i] - padding) + ',' + (20 - padding) + ')';
    };
    yearLabels['border']
      .attr('display', null)
      .attr('x', function (d, i) {
        return bboxes[i].x;
      })
      .attr('y', function (d, i) {
        return bboxes[i].y;
      })
      .attr('width', function (d, i) {
        return bboxes[i].width + padding * 2;
      })
      .attr('height', function (d, i) {
        return bboxes[i].height + padding * 2;
      })
      .attr('transform', function (d, i) {
        return translate(i);
      });

  };

  timeline.brushended = function (d, i) {
    const d3Event = require("d3-selection").event;
    var s;
    if (d3Event) {
      s = d3Event.selection;
    }
    if (s == null) {
      yearLabels['text']
        .attr('display', 'none');
      yearLabels['border']
        .attr('display', 'none');
    }
    else {
      var ext = s.map(function (p) {
        return parseInt(yearFormatter(x.invert(p)), 10);
      });
      setDatesCallback(ext);
    }
  };

  timeline.addBars = function (direction) {
    if (deathBar !== undefined) {
      deathBar.remove();
    }
    if (birthBar !== undefined) {
      birthBar.remove();
    }

    svg.select('.brush').call(brush.move, null);

    if (direction === 'left') {
      x.domain([yearMin, yearParser(timelineSeparator)]);
      buttonLeft
        .classed('disabled', true)
        .on('click', null);
      buttonRight
        .classed('disabled', false)
        .on('click', function () {
          timeline.addBars('right');
        });
    }
    else {
      x.domain([yearParser(timelineSeparator), yearMax]);
      buttonLeft.classed('disabled', false)
        .on('click', function () {
          timeline.addBars('left');
        });
      buttonRight
        .classed('disabled', true)
        .on('click', null);
    }

    barWidth = ( x(yearParser(2000)) - x(yearParser(1999)) ) * 0.9;

    // append the rectangles for the bar chart
    birthBar = barGroup.selectAll('.birth-bar')
      .data(data)
      .enter().append('rect')
      .classed('birth-bar', true)
      .attr('id', function (d) {
        return yearFormatter(d.year);
      })
      .attr('value', function (d) {
        return d.birth;
      })
      .attr('x', function (d) {
        return x(d.year);
      })
      .attr('width', barWidth)
      .attr('y', function (d) {
        return y(d.birth);
      })
      .attr('height', function (d) {
        return y(0) - y(d.birth)
      });

    // append the rectangles for the bar chart
    deathBar = barGroup.selectAll('.death-bar')
      .data(data)
      .enter().append('rect')
      .classed('death-bar', true)
      .attr('id', function (d) {
        return yearFormatter(d.year);
      })
      .attr('value', function (d) {
        return d.death;
      })
      .attr('x', function (d) {
        return x(d.year);
      })
      .attr('width', barWidth)
      .attr('y', y(0))
      .attr('height', function (d) {
        return y(0) - y(d.death);
      });
  };

  timeline.moveBrushSelection = function (yearSelection) {
    const brushSelection = yearSelection.map((year) => {
      return x(yearParser(year));
    });
    svg.select('.brush').call(brush.move, brushSelection);
  };

  return timeline;
};

export default exports;
