/**
 * @module womeninbotany.list.whenScrolledComponent
 */

import womeninbotanyDebounceService from 'womeninbotany/utility/DebounceService.js';


/**
 * @type {!angular.Module}
 */
const exports = angular.module('womeninbotanyWhenScrolled', [
  womeninbotanyDebounceService.module.name,
]);


/**
 * Provides a directive for progressively loading a list
 *
 * @param {angular.$window} $window Angular $window service.
 * @return {angular.Directive} Directive Definition Object.
 * @ngInject
 * @ngdoc directive
 * @ngname womeninbotanyTimelineDirective
 *
 */
exports.directive_ = function ($window, womeninbotanyDebounceService) { // for progressively loading botanist list
	return {
		restrict: 'A',
		/**
		 * @param {angular.Scope} scope Scope.
		 * @param {angular.JQLite} element Element.
		 * @param {angular.Attributes} attrs Atttributes.
		 */
		link(scope, element, attrs) {
			var raw = element[0];
			$(window).scroll(womeninbotanyDebounceService.debounce(function (e) {
				if (($(window).scrollTop() + $(window).height()) >= ($(raw).offset().top + $(raw).height())) {
					scope.$apply(attrs['womeninbotanyWhenScrolled']);
				}
			}, 100));
		}
	};
};

exports.directive('womeninbotanyWhenScrolled', exports.directive_);

export default exports;
