/**
 * @module app.MainController
 */
/**
 * Application entry point.
 */


const exports = {};

  import 'womeninbotany/less/womeninbotany.less';
  import angular from 'angular';
  import 'angular-sanitize';
  import olMap from 'ol/Map.js';
  import olView from 'ol/View.js';
  import olGeolocation from 'ol/Geolocation';
  import olLayerTile from 'ol/layer/Tile.js';
  import olSourceXYZ from 'ol/source/XYZ.js';
  import olStyleStyle from 'ol/style/Style.js';
  import olStyleCircle from 'ol/style/Circle.js';
  import olStyleFill from 'ol/style/Fill.js';
  import olStyleStroke from 'ol/style/Stroke.js';
  import ngeoMapModule from 'ngeo/map/module.js';
  import olLayerVector from 'ol/layer/Vector.js';
  import olSourceVector from 'ol/source/Vector.js';
  import olMousePosition from 'ol/control/MousePosition';
  import * as olProj from 'ol/proj.js';
  import {ATTRIBUTION as OSMATTRIBUTION} from 'ol/source/OSM.js';
  import {defaults as defaultControls} from 'ol/control.js';
  import {createStringXY} from 'ol/coordinate.js';
  import {toStringHDMS} from 'ol/coordinate.js';
  import womeninbotanyMapModule from 'womeninbotany/map/module.js';
  import womeninbotanySidebarModule from 'womeninbotany/sidebar/module.js';
  import womeninbotanyListModule from 'womeninbotany/list/module.js';
  import womeninbotanyTimelineModule from 'womeninbotany/timeline/module.js';
  import womeninbotanyInfopageModule from 'womeninbotany/infopage/module.js';
  import womeninbotanyBotanistService from 'womeninbotany/list/BotanistService.js';
  import womeninbotanyPlaceService from 'womeninbotany/map/PlaceService.js';
  import womeninbotanyReportpageModule from 'womeninbotany/reportpage/module.js';
  import womeninbotanyScrollOnClickComponent from 'womeninbotany/utility/scrollOnClickComponent';
  import 'font-awesome/css/font-awesome.css';


/** @type {!angular.Module} **/
exports.module = angular.module('womeninbotanyapp', [
  'ngSanitize',
  ngeoMapModule.name,
  womeninbotanySidebarModule.name,
  womeninbotanyMapModule.name,
  womeninbotanyListModule.name,
  womeninbotanyTimelineModule.name,
  womeninbotanyBotanistService.module.name,
  womeninbotanyPlaceService.module.name,
  womeninbotanyInfopageModule.name,
  womeninbotanyReportpageModule.name,
  womeninbotanyScrollOnClickComponent.name,
]);


exports.module.constant('mapboxURL', 'https://api.mapbox.com/styles/v1/' +
  'sca21002/cip8kcaih002zcuns1cle262m/tiles/{z}/{x}/{y}?access_token=' +
  'pk.eyJ1Ijoic2NhMjEwMDIiLCJhIjoieWRaV0NrcyJ9.g6_31qK3mtTz_6gRrbuUGA');

exports.module.constant('womeninbotanyServerURL', SERVER);

/**
 * @param {string} mapboxURL Url to Mapbox tile service.
 * @constructor
 * @ngInject
 */
exports.MainController = function($scope, $location, mapboxURL, womeninbotanyBotanistService, womeninbotanyPlaceService) {


  this.isInfoBoxVisible = true;
  this.scope        = $scope;
  this.location     = $location;
  this.mapboxURL      = mapboxURL;
  this.womeninbotanyPlace = womeninbotanyPlaceService;

//  FIXME:
//  /**
//   * @type {string}
//   * @export
//   */
//  this.svgIconUrl = `${womeninbotany.baseTemplateUrl}/icons/icons.svg`;


  /**
   * @type {boolean}
   * @export
   */
  this.dataLoaded = false;

  /**
   * @type {boolean}
   * @export
   */
  this.infopageShown = false;

  /**
   * @type {boolean}
   * @export
   */
  this.reportpageShown = false;

  /**
   * @type {boolean}
   * @export
   */
  this.alertShown = false;

  /**
   * @type {Array}
   * @export
   */
  this.botanistsPerYear = [];

  /**
   * @type {Object}
   * @export
   */
  this.maxDates;

  /**
   * @type {boolean}
   * @export
   */
  this.modalPrivacyShown = false;

  /**
   * @type {int}
   * @export
   */
  this.botanistsTotal = 0;

  /**
   * @type {int}
   * @export
   */
  this.botanistsOnMap = 0;

  /**
   * @type {womeninbotanyx.FilterRules}
   * @export
   */
  this.filterRules = {
    timelineDates: [],   // it's important to initialize as an array
    // otherwise angular creates an object
    events     : null,
    categories   : null,
    search     : '',
    searchTerms: [],
    plant: '',
	  plantName: '',
    place    : ''
  };

  const setDatesCallback = function (yearExtent) {
    this.filterRules.timelineDates = yearExtent;
  }.bind(this);

  const maxExtentCallback = function (maxExtent) {
    this.maxDates = maxExtent;
  }.bind(this);

  /**
   * @type {Object}
   * @export
   */
  this.timelineOptions = {
    setDatesCallback : setDatesCallback,
    maxExtentCallback: maxExtentCallback
  };

  /**
   * @return {Array<ol.style.Style>} returns a style definition
   * @export
   */
  this.placeStyleFn = this.getPlaceStylesFunction_();

  this.placesSource = new olSourceVector({
    features: [],
    wrapX : false
  });

  this.initView_();

  this.initMap_();


  this.initMapEvents_();


  var protocol = $location.protocol();

  if (protocol === 'https') {

    // Fix: better as a separate function
    var geolocation = new olGeolocation({
      // start immediately
      tracking: true,
      // take the projection to use from the map's view
      projection: this.view.getProjection()
    });

    // listen to changes in position
    geolocation.once('change:position', function(evt) {
      var pos = geolocation.getPosition();
      this.view.setCenter(pos);
    }.bind(this));

    // handle geolocation error.
    geolocation.on('error', function(error) {
      console.log(error.message);
    });
  }


  womeninbotanyBotanistService.yearOfEvent().then(function (data) {
    this.botanistsPerYear = data;
  }.bind(this));

  this.initScopeEvents_();

};


/**
 *
 * @private
 */
exports.MainController.prototype.getPlaceStylesFunction_ = function () {
  // get placetype-specific color
  const getPlaceTypeColour = function (placeType) {
    if (placeType == "b") {
      return "#6dc066";
    }
    else if (placeType == "d") {
      return "#f30f3c";
    }
    else if (placeType == "w") {
      return "#74c7ff";
    }
    return "";
  };

  const getPlaceTypeStroke = function (placeType) {
    if (placeType == "b")
      return "#393";
    else if (placeType == "d")
      return "#933";
    else if (placeType == "w")
      return "#55aaee";
    else
      return "#777";
  };

  const getStrokeWidth = function (res) {
    if (res > 4000) return 0;
    if (res > 2000) return 1;
    return 2;
  }

  const getRadius = function(res) {
    if (res > 4000) return 1;
    if (res > 2000) return 2;
    return 3;
  }

  return function (feature, res) {
    return [new olStyleStyle({
      image: new olStyleCircle({
        fill     : new olStyleFill({
          color: getPlaceTypeColour(feature.get('type'))
        }),
        stroke     : new olStyleStroke({
          color: getPlaceTypeStroke(feature.get('type')),
          width: getStrokeWidth(res),
        }),
        radius     : getRadius(res),
        snapToPixel: false
      })
    })];
  };
};

exports.MainController.prototype.initView_ = function () {
  this.center   = [10.581, 49.682];
  this.zoom   = 8;
  this.MAX_ZOOM = 20;

  this.view = new olView({
    center : olProj.transform(
      this.center, 'EPSG:4326', 'EPSG:3857'
    ),
    zoom   : this.zoom,
    maxZoom: this.MAX_ZOOM,
    minZoom: 2,
	  projection: 'EPSG:3857',
	  displayProjection: 'EPSG:4326',
  });
};

exports.MainController.prototype.initMap_ = function () {
	const placesLayer = new olLayerVector({
		name  : 'places',    // as criteria in layer filter function
		source: this.placesSource,
		style : this.placeStyleFn
	});

	const tilesLayer = new olLayerTile({
		source: new olSourceXYZ({
			attributions: [
				'© <a href="https://www.mapbox.com/about/maps/">Mapbox</a>',
				OSMATTRIBUTION,
				'<a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a>'
			],
			crossOrigin: 'Anonymous',
			tileSize  : [512, 512],
			preload   : true,
			url     : this.mapboxURL,
			wrapX   : false,
			wrapDateLine: false,
			noWrap    : true
		})
	})


  /**
   * @type {ol.Map}
   * @export
   */
  this.map = new olMap({
    layers: [
      tilesLayer,
      placesLayer
    ],
    controls: defaultControls({
      attributionOptions: {
        collapsible: false
      }
    }).extend([
      new olMousePosition({
        coordinateFormat: toStringHDMS,
        projection: 'EPSG:4326'
      })
    ]),
    view  : this.view
  });

  this.map.once('postrender', function (event) {
    this.postActions_();
  }.bind(this));
};

/**
 *
 * @private
 */
exports.MainController.prototype.postActions_ = function () {
  // fade in content
  const pageContent = angular.element(document.querySelector('#page-content-wrapper'));
  pageContent.addClass('page-content-wrapper--loaded');
};

/**
 *
 * @private
 */
exports.MainController.prototype.initMapEvents_ = function () {
  this.getExtent = function () {
    var extent = this.map.getView().calculateExtent(
      /** @type {ol.Size} */
      (this.map.getSize())
    );
    return extent;
  };

  this.extent = null;

	this.map.on('moveend', (evt) => {

		let newZoom = this.map.getView().getZoom()

		if (newZoom != this.zoom) {
			this.zoom = newZoom
			// this.map.render()
			// this.map.updateSize()
		}

		this.extent = this.getExtent();
		this.scope.$applyAsync();
	})
};

/**
 *
 * @private
 */
exports.MainController.prototype.initScopeEvents_ = function () {
  this.scope.$watch(
    function () {
      return [this.filterRules, this.extent];
    }.bind(this),
    function (newValue, oldValue) {
      if (!angular.equals(newValue[0], oldValue[0])) {
      }
      else {
      }
      if (this.extent != null) {
        this.updateSearchTerms_();
        this.updatePlacesSource_();
      }
    }.bind(this), true);
};

/**
 *
 * @private
 */
exports.MainController.prototype.updatePlacesSource_ = function () {
  this.womeninbotanyPlace.getPlaces(this.filterRules, this.extent).then(function (features) {
    this.dataLoaded = true;

    this.placesSource.clear();
    if (features != null) {
      this.placesSource.addFeatures(features);
    }
    this.scope.$applyAsync();
  }.bind(this));
};

/**
 *
 * @private
 */
exports.MainController.prototype.updateSearchTerms_ = function() {
  this.filterRules.searchTerms = []

  for (let key in this.filterRules) {
    if (this.filterRules.hasOwnProperty(key)) {
      if (key == 'search') {
      	// TODO: key == 'place' currently takes the place id and not the place name
        if (this.filterRules[key].length > 0) {
          this.filterRules.searchTerms.push(this.filterRules[key])
        }
      } else if (key == 'plant') {
	      if (this.filterRules['plantName'].length > 0) {
		      this.filterRules.searchTerms.push(this.filterRules['plantName'])
	      }
      }
    }
  }
};

/**
 *
 * @export
 */
exports.MainController.prototype.showInfoPage = function () {
  this.infoPageHidden = !this.infoPageHidden;
};

/**
 *
 * @export
 */
exports.MainController.prototype.showOnMap = function (longitude, latitude) {
  const lonLat = olProj.transform([longitude, latitude], 'EPSG:3857', 'EPSG:4326');
  this.map.getView().setCenter(olProj.transform([lonLat[0], lonLat[1]], 'EPSG:4326', 'EPSG:3857'));

  // if (this.map.getView().getZoom() < this.MAX_ZOOM) {
  //   this.map.getView().setZoom(this.map.getView().getZoom() + (this.MAX_ZOOM - this.map.getView().getZoom()));
  // }
  this.map.getView().setZoom(12);
};

/**
 *
 * @export
 */
exports.MainController.prototype.clearFilters = function () {
  const newFilterRules = {
    timelineDates: [],   // it's important to initialize as an array
    // otherwise angular creates an object
    events     : null,
    categories   : null,
    search     : '', // person
    searchTerms: [], // search term used for highlighting
    place: '',
    plant: '',
	  plantName: ''
  };

  // retain the alphabetic list filtering
  if (this.filterRules && this.filterRules.hasOwnProperty('letter')) {
    newFilterRules['letter'] = this.filterRules['letter'];
  }
  this.filterRules = newFilterRules;
  this.updatePlacesSource_();
};

exports.module.controller('MainController', exports.MainController);

export default exports;
