/**
 * @module womeninbotany.statistics.pieChartComponent
 */

import googAsserts from 'goog/asserts.js';
import * as olObj from 'ol/obj.js';
import womeninbotanyStatisticsD3Piechart from 'womeninbotany/statistics/d3Piechart.js';

import {select} from 'd3-selection';
const d3 = {
  select,
};

/**
 * @type {!angular.Module}
 */
const exports = angular.module('womeninbotanyPieChart', [
]);

/**
 * Provides a directive used to insert a pie chart
 *
 * @return {angular.Directive} Directive Definition Object.
 * @ngInject
 * @ngdoc directive
 * @ngname womeninbotanyPieChartDirective
 */
exports.directive_ = function() {
  return {
    restrict: 'A',
    /**
     * @param {angular.Scope} scope Scope.
     * @param {angular.JQLite} element Element.
     * @param {angular.Attributes} attrs Atttributes.
     */
    link(scope, element, attrs) {
      const pieChartOptions = attrs['womeninbotanyPieChartOptions'];
      googAsserts.assert(pieChartOptions !== undefined);

      const selection = d3.select(element[0]);
      let pieChart, categoryData;

      scope.$watchCollection(pieChartOptions, (newVal) => {

        // const options = /** @type {womeninbotanyx.pieChart.PieChartOptions} */
        //  (ol.obj.assign({}, newVal));

        const options = /** @type {womeninbotanyx.pieChart.PieChartOptions} */
          (olObj.assign({}, newVal));



        if (options !== undefined) {

          // proxy the setDatesCallback and maxExtentCallback in order to be
          // able to call $applyAsync
          // We're using $applyAsync here because the callback may be
          // called inside the Angular context.
          //
          // For that reason we use $applyAsync instead of $apply here.
          if (options.setDatesCallback !== undefined) {
            const origSetDatesCallback = options.setDatesCallback;
            options.setDatesCallback = function(...args) {
              origSetDatesCallback(...args);
              scope.$applyAsync();
            };
          }

          if (options.maxExtentCallback !== undefined) {
            const origMaxExtentCallback = options.maxExtentCallback;
            options.maxExtentCallback = function(...args) {
              origMaxExtentCallback(...args);
              scope.$applyAsync();
            };
          }

          pieChart = womeninbotanyStatisticsD3Piechart(options);
          refreshData();
        }
      });

      scope.$watch(
        attrs['womeninbotanyPieChart'],
        (newVal, oldVal) => {
          categoryData = newVal;
          refreshData();
        }
      );

      function refreshData() {
        if (pieChart !== undefined) {
          selection.datum(categoryData).call(pieChart);
        }
      }
    }
  };
};

exports.directive('womeninbotanyPieChart', exports.directive_);

export default exports;
