/**
 * @module womeninbotany.map.tooltipComponent
 */

import googAsserts from 'goog/asserts.js';
import olMap from 'ol/Map.js';
import * as olEvents from 'ol/events.js';
import olOverlay from 'ol/Overlay.js';

/**
 * @type {!angular.Module}
 */
const exports = angular.module('womeninbotanyTooltip', [
]);

exports.run(/* @ngInject */ ($templateCache) => {
  $templateCache.put('womeninbotany/map/tooltipComponent', require('./tooltip.html'));
});


exports.component_ = {
  controller: 'womeninbotanyMapTooltipController',
  bindings: {
    'getMapFn': '&womeninbotanyTooltipMap'
  },
  templateUrl: 'womeninbotany/map/tooltipComponent'
};

exports.component('womeninbotanyTooltip', exports.component_);

/**
 * @constructor
 * @export
 * @ngInject
 * @ngdoc Controller
 * @ngname WomeninbotanyTooltipController
 */
exports.Controller_ = function() {
  /**
   * @private
   */
  this.HIT_TOLERANCE = 4;

  /**
   * @type {ol.Overlay}
   * @private
   */
  this.overlay_;

  // wait until constructor has done its initialization
  this.$onInit = function() {
    const map  = this['getMapFn']();
    googAsserts.assertInstanceof(map, olMap);

    olEvents.listen(map, 'pointermove', this.handlePointerMove_, this);

    this.overlay_ = new olOverlay({
      element: document.getElementById('tooltip'),
      positioning: 'top-right'
    });

    map.addOverlay(this.overlay_);
  };
};

exports.Controller_.prototype.handlePointerMove_ = function(evt) {

    const map = evt.map;
    const IsInsideCircleOfSpread = map.forEachLayerAtPixel(evt.pixel, (layer) => {
      return layer.get('name') === 'circle-of-spread';
    });

    if (IsInsideCircleOfSpread) {

        // is the cursor over a spreaded point
        const features = map.getFeaturesAtPixel(evt.pixel, {
          layerFilter: (layer) => {
            return layer.get('name') === 'spreaded-points';
          },
          hitTolerance: this.HIT_TOLERANCE
        });

        if (features && features.length > 0) {
          const feature = features[0];     // spreaded points don't overlap
          let text = feature.get('familyname');
          if (feature.get('firstname')) {
            text += ', ' + feature.get('firstname');
          }
          const coordinates = feature.getGeometry().getCoordinates();
          this.showTooltip_(text, coordinates);

        } else {   // cursor is inside the circle and away from the ring of points

            //fetch the circle feature
            let features = map.getFeaturesAtPixel(evt.pixel, {
            layerFilter: (layer) => {
              return layer.get('name') === 'circle-of-spread';
            }
          });
          const feature = features[0];   // there is only one circle
          const text = feature.get('placename');
          const coordinates = feature.getGeometry().getCenter();
          this.showTooltip_(text, coordinates);
        }

    } else {  // cursor outside of spreaded points

      // search for nearest point
      let minSquaredDistance = Infinity;
      let nearestFeature = null;
      map.forEachFeatureAtPixel(evt.pixel,
        (feature) => {
          const p1 = evt.coordinate;
          const p2 = feature.getGeometry().getCoordinates();
          const dy = p2[1] - p2[1];
          const dx = p2[0] - p1[0];
          const squaredDistance = dy * dy + dx * dx;
          if (squaredDistance < minSquaredDistance) {
            minSquaredDistance = squaredDistance;
            nearestFeature = feature;
          }
        },
        {
          layerFilter: (layer) => {
            return layer.get('name') === 'places';
          },
          hitTolerance: this.HIT_TOLERANCE
        }
      );

      if (nearestFeature != null) {   // there is a point near the cursor
        const feature = nearestFeature;
        const text = feature.get('placename');
        const coordinates = feature.getGeometry().getCoordinates();
        this.showTooltip_(text, coordinates);

      } else {      // cursor is in the middle of nowhere
        this.clearTooltip_();
      }
    }

};

exports.Controller_.prototype.showTooltip_ = function(text, coordinates) {
  const content = document.getElementById('tooltip-text');
  content.innerHTML = text;
  this.overlay_.setPosition(coordinates);
}

exports.Controller_.prototype.clearTooltip_ = function() {
  this.overlay_.setPosition(undefined);
}

exports.controller('womeninbotanyMapTooltipController', exports.Controller_);

export default exports;
