/**
 * @module womeninbotany.infopage.component
 */

import ngeoMessageModalComponent from 'ngeo/message/modalComponent.js';

/**
 * @type {!angular.Module}
 */
const exports = angular.module('womeninbotanyInfoPage', [
  ngeoMessageModalComponent.name,
]);

exports.run(/* @ngInject */ ($templateCache) => {
  $templateCache.put('womeninbotany/infopage', require('./component.html'));
});


exports.component_ = {
  controller: 'WomeninbotanyInfopageController',
  bindings: {
    'infopageShown': '=womeninbotanyInfopageShown'
  },
  templateUrl: 'womeninbotany/infopage'
};

exports.component('womeninbotanyInfoPage', exports.component_);

exports.Controller_ = function() {
  this.infopageShown;
};

exports.controller('WomeninbotanyInfopageController', exports.Controller_);

export default exports;
